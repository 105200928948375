export const FA = {
    fa: "FA",
    en: "EN",
    login: "ورود",
    existEmail: "این ایمیل قبلا ثبت شده",
    existMobile: "این شماره موبایل قبلا ثبت شده",
    emptyEmail: "ایمیل خود را وارد کنید",
    emptyMobile: "شماره موبایل خود را وارد کنید",
    wrongEmail: "فرمت ایمیل اشتباه است",
    wrongMobile: "فرمت شماره موبایل اشتباه است",
    emptyEmailOrMobile: "ایمیل خود را وارد کنید",
    loginError: "ایمیل یا رمزعبور اشتباه است",
    emailOrMobile: "ایمیل یا موبایل",
    first_name: "نام",
    last_name: "نام‌خانوادگی",
    phone: "شماره تلفن",
    passwordConfirm: "تکرار رمزعبور",
    weakPassword: "رمزعبور باید حداقل ۶ کاراکتر، شامل عدد و حروف باشد",
    passwordStrength: "حداقل ۶ کاراکتر، شامل عدد و حروف",
    passwordChanged: "رمزعبور با موفقیت تغییر کرد",
    currentPasswordError: "رمزعبور فعلی صحیح نیست",
    currentPassword: "رمزعبور فعلی",
    register: "ثبت‌نام",
    doRegister: "ثبت‌نام کنید",
    dontHaveAccount: "حساب کاربری ندارید؟",
    haveAccount: "حساب کاربری دارید؟",
    forgetPass: "فراموشی رمز عبور",
    loginWithToken: "ورود با شماره موبایل",
    password: "رمزعبور",
    backToLogin: "بازگشت به صفحه ورود",
    doLogin: "وارد شوید!",
    rules: "قوانین و مقررات",
    withRegistering: "با ثبت نام در این سایت",
    privacyPolicy: "قوانین حریم خصوصی",
    privacyPolicyConfirmation: "آن را می‌پذیرم",
    mobile: "شماره همراه",
    mobileSample: "مثل 09123456789",
    email: "ایمیل",
    sms: "پیام کوتاه",
    verifyEmail: "تایید ایمیل",
    registerSuccess: "کاربر با موفقیت ثبت شد",
    registerError: "خطا در ایجاد کاربر",
    youLoggedOff: "شما از پنل خارج شدید",
    mobileVerified: "شماره موبایل شما تایید شد",
    wrongToken: "کد تایید اشتباه است ",
    mobileVerify: "تایید موبایل",
    verifyMobile: "تایید شماره موبایل",
    sendTokenText1: "رمز یکبارمصرف شما به شماره",
    sendTokenText2: "ارسال شد",
    sendTokenTextResend: "رمز یکبار مصرف مجددا برای شما ارسال شد",
    sendTokenText3: "زمان باقی مانده تا ارسال مجدد پیامک",
    logOut: "خروج از سایت",
    confirm: "تایید",
    token: "کد تایید",
    forgetPassword: "فراموشی رمزعبور",
    invalidMobileNumber: "این شماره در سیستم وجود ندارد",
    recoverPassword: "بازیابی رمزعبور",
    recoverPasswordText: "برای بازیابی رمز عبور، شماره موبایل خود را وارد کنید",
    sendAgain: "ارسال دوباره",
    changePassword: "تغییر رمزعبور",
    managePassword: "مدیریت رمزعبور",
    newPassword: "رمزعبور جدید",
    newPasswordConfirm: "تکرار رمزعبور جدید",
    loginWithUserPass: "ورود با ایمیل",
    addYourNumberFirst: "برای ورود به سایت، شماره موبایل خود را وارد کنید",
    errorHappens: "در بارگذاری سایت خطایی رخ داده است!",
    allChangesSaved: "تمام تغییرات ذخیره شد",
    allChangesSavingFailed: "ذخیره سازی تغییرات با مشکل مواجه شد!",
    connectionError: 'اینترنت شما قطع است',
    pleaseCheckTheBox: "لطفا قوانین را تایید کنید!",
    emptyField: 'فیلد خالی است',
    makingTokenError: "در ایجاد کد تایید مشکلی به وجود آمده است ",
    editMobile: "تغییر شماره موبایل",
    editMobileDesc: "در صورتی که قصد تغییر شماره موبایل خود (",
    editMobileDesc2: ") را دارید، آن را وارد کنید",
    save: "ذخیره",
    dataAdded: "اطلاعات شما ایجاد شد",
    dataAddingFailed: "در ایجاد اطلاعات مشکلی به وجود آمده است",
    emptyPassword: "رمز عبور را وارد کنید",
    unreadNotifications: "اعلان‌های خوانده نشده",
    readNotifications: "اعلان‌های خوانده شده",
    noNotifications: "اعلانی وجود ندارد!",
    nothingFound: "صفحه وجود ندارد!",
    markAsRead: 'خوانده شد!',
    copyright: " تمام حقوق محفوظ است © ",
    dashboard: "داشبورد",
    account: "حساب کاربری",
    fileManager: "مدیریت فایل‌ها",
    myDetails: "اطلاعات من",
    myAccount: "حساب کاربری",
    doYouWanaExit: "آیا قصد خروج دارید؟",
    readMore: "بیشتر بخوانید!",
    areYouSure: "آیا از انجام این کار مطمئنید؟",
    yes: "بله",
    no: "خیر",
    userPanel: "پنل کاربری",
    userMobileIsConfirmed: "شماره موبایل کاربر تایید شده است",
    userMobileIsNotConfirmed: "شماره موبایل کاربر تایید نشده است",
    doYouWanaConfirm: "شماره موبایل کاربر را تایید می‌کنید؟",
    loginToUserAccount: "ورود به اکانت این کاربر",
    userShouldConfirmMobileFirst: "ابتدا باید شماره موبایل کاربر تایید شود!",
    youAreInSomeOneAccount: "شما در حساب کاربری فرد دیگری هستید!",
    backToMyAccount: "بازگشت",
    setDetails: "ثبت اطلاعات",
    detailsSet: "اطلاعات ثبت شد",
    detailsSetFailed: "ثبت اطلاعات با مشکل مواجه شد",
    detailsGetFailed: "دریافت اطلاعات با مشکل مواجه شد",
    someFieldsHasErrorPleaseCheckThem: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی نمایید",
    someFieldsHasError: "یک یا چند فیلد دارای خطا هستند، لطفا بررسی کنید",
    maxLength70: "طول این فیلد نباید بیش از ۷۰ کاراکتر باشد",
    maxLength150: "طول این فیلد نباید بیش از ۱۵۰ کاراکتر باشد",
    maxLength255: "طول این فیلد نباید بیش از ۲۵۵ کاراکتر باشد",
    maxLength450: "طول این فیلد نباید بیش از ۴۵۰ کاراکتر باشد",
    maxLength500: "طول این فیلد نباید بیش از ۵۰۰ کاراکتر باشد",
    maxLength1000: "طول این فیلد نباید بیش از ۱۰۰۰ کاراکتر باشد",
    maxLength6000: "طول این فیلد نباید بیش از ۶۰۰۰ کاراکتر باشد",
    chooseMedia: "انتخاب رسانه",
    submitErrorFile: "فایل ارسالی مشکل دارد",
    imageSizeWidth700: "عرض مناسب تصویر 700px می‌باشد",
    thumbnailSize: " حداقل عرض مناسب 600px",
    userAvatarSet: "تصویر پروفایل شما بروز شد",
    profileImage: "تصویر پروفایل",
    userAvatarSetFailed: "در تغییر تصویر پروفایل مشکلی به وجود آمده است",
    loadingThumbnailFailed: "در بارگذاری تصویر مشکلی پیش آمده",
    ChooseImageLink: " برای کپی لینک، سایز مورد نظر را انتخاب کنید",
    deleteFile: "فایل با موفقیت حذف شد",
    deleteFileFailed: "حذف فایل با مشکل رو به رو شد",
    deleteSelectedFiles: "فایل ها با موفقیت حذف شدند",
    alt: "متن جایگزین (alt)",
    chooseImage: "انتخاب تصویر",
    emptyImage: "تصویری انتخاب کنید!",
    descriptionPlaceHolder: "توضیحات مربوطه را بنویسید",
    words: "کلمه",
    characters: "کاراکتر",
    uploadImage: "آپلود رسانه",
    ThumbnailTitle: "تصویر شاخص",
    loading: "درحال بارگذاری...",
    makePassword: "ایجاد رمز عبور",
    addImage: "افزودن تصویر",
    imageName: "نام فایل",
    ownerMedia: "ناشر",
    insertMedia: "درج فایل",
    insert: "درج",
    linkCopied: "لینک کپی شد",
    uploadSuccess: "آپلود با موفقیت انجام شد",
    editFile: "فایل با موفقیت ویرایش شد",
    editFileFailed: "ویرایش فایل با مشکل رو به رو شد",
    tableAdded: "جدول ایجاد شد",
    tableAddingFailed: "افزودن جدول با مشکل مواجه شد",
    deleteTable: "جدول با موفقیت حذف شد",
    deleteTableFailed: "حذف جدول با مشکل رو به رو شد",
    tableEdited: "جدول ویرایش شد",
    tableEditingFailed: "ویرایش جدول با مشکل مواجه شد",
    selfFiles: "فایل‌های من",
    adminsFiles: "فایل‌های مدیران سایت",
    everyBodyFiles: "فایل‌های همه",
    uploading: "درحال آپلود...",
    uploadDone: "آپلود شد!",
    allTypes: "همه فرمت‌ها",
    image: "تصویر",
    video: "ویدیو",
    document: "سند",
    chooseVideo: "انتخاب ویدیو",
    multiImport: "انتخاب رسانه‌ها",
    filesTable: "فایل‌های ذخیره شده",
    lastUpdate: "آخرین بروزرسانی",
    search: "جستجو",
    from: "از",
    labelRowsSelect: "ردیف",
    labelRowsPerPage: "تعداد ردیف‌ها در هر صفحه",
    firstAriaLabel: "صفحه اول",
    firstTooltip: "صفحه اول",
    previousAriaLabel: "صفحه قبل",
    previousTooltip: "صفحه قبل",
    nextAriaLabel: "صفحه بعد",
    nextTooltip: "صفحه بعد",
    lastAriaLabel: "صفحه آخر",
    lastTooltip: "صفحه آخر",
    exportTitle: "دریافت فایل خروجی",
    exportAriaLabel: "دریافت فایل خروجی",
    exportCSVName: "دریافت خروجی CSV",
    emptyDataSourceMessage: 'محتوایی برای نمایش وجود ندارد!',
    addTooltip: 'افزودن',
    deleteTooltip: 'پاک کن',
    editTooltip: 'ویرایش سریع',
    filterTooltip: 'فیلتر',
    deleteText: 'آیا از حذف این ردیف مطمئنید؟',
    cancelTooltip: 'لغو',
    saveTooltip: 'ذخیره',
    rowsSelected: 'ردیف انخاب شده',
    uploadedDate: "زمان آپلود",
    linkPlaceholder: "یک آدرس اینترنتی وارد کنید",
    number: "عدد",
    icon: "آیکون",
    link: "لینک ",
    filters: "فیلتر‌ها",
    chooseColor: "انتخاب رنگ",
    chooseFormat: "انتخاب فرمت",
    editAdvertisement: "ویرایش ویترین",
    makeAdvertisement: "ایجاد ویترین",
    advertisementName: "تیتر ویترین",
    advertisements: "ویترین‌ها",
    advertisement: "ویترین",
    advertisementsList: "لیست ویترین‌ها",
    viewAdvertisement: "مشاهده ویترین",
    updateTime: "بروزرسانی",
    created_at: "تاریخ ایجاد",
    updated_at: "تاریخ بروزرسانی",
    gallery: 'گالری تصاویر',
    requestToDeleteAd: "درخواست حذف ویترین",
    removeAd: "حذف ویترین",
    requestedToDelete: "درخواست حذف ارسال شد!",
    pageNotFound: "این صفحه در دسترس نیست!",
    contentAdded: "نوشته‌ی شما ایجاد شد",
    contentAddingFailed: "در ایجاد نوشته مشکلی به وجود آمده است",
    contentEdited: "نوشته‌ی شما ویرایش شد",
    contentEditingFailed: "در ویرایش نوشته مشکلی به وجود آمده است",
    deleteContent: "نوشته‌ حذف شد",
    deleteContentFailed: "در حذف نوشته مشکلی به وجود آمده است",
    firstConfirmShop: "ابتدا فروشگاه این کاربر را تایید کنید!",
    confirmationStatusDescription: "وضعیت تایید اطلاعات",
    confirmationStatusFilter: "وضعیت تایید",
    confirmStatus: "وضعیت تایید",
    userConfirmationStatus: "وضعیت تایید اطلاعات کاربر",
    confirmed: "تایید شده",
    unConfirmed: "در انتظار تایید",
    all: "همه",
    yourAdvertisementIsActive: "تبریک! ویترین شما تایید شده و فعال است",
    yourAdvertisementIsInchecking: "ویترین شما در دست بررسی است!",
    price: "قیمت",
    changeCategories: "تغییر دسته‌بندی‌",
    advertisementDescription: "توضیح کامل ویترین",
    seoDescription: "توضیح اجمالی",
    welcomeToPanel: "به پنل کاربری فیل خوش آمدید!",
    agreement: "قوانین و مقررات",
    pleaseConfirmTerms: "لطفا صفحه شرایط استفاده را مطالعه نموده و تیک تایید را بزنید",
    rulesConfirmation: "موافقم و آن را می‌پذیرم",
    with: "با ",
    manageAdvertisements: "مدیریت ویترین‌ها",
    myAdvertisements: "ویترین‌های من",
    thumbnail: "تصویر",
    creator: "ناشر",
    edit: "ویرایش",
    view: "مشاهده",
    activity: 'فعالیت',
    details: 'جزئیات',
    deleteAdvertisement: "ویترین حذف شد!",
    deleteAdvertisementFailed: "در حذف ویترین مشکلی به وجود آمده است!",
    chooseCategory: "انتخاب دسته‌بندی‌",
    maxGallerySizeIs9: "حداکثر تصاویر گالری ۹ عدد است!",
    manageComments: "مدیریت دیدگاه‌ها",
    manageCategories: "مدیریت دسته‌بندی‌ها",
    usersManagement: "مدیریت کاربران",
    allUsers: "همه کاربران",
    usersActivity: "فعالیت کاربران",
    managePages: "مدیریت صفحات",
    manageContact: "فرم تماس",
    siteSettings: "تنظیمات سایت",
    siteInformation: "تنظیمات اصلی سایت",
    manageSlideShows: "اسلایدر صفحه‌اصلی‌",
    manageAllSlideShows: "مدیریت اسلایدرها‌",
    manageBanners: "مدیریت بنر‌ها",
    manageHomeBanners: "اسلایدر بنر صفحه‌اصلی",
    editSlideShow: "ویرایش اسلایدشو‌",
    custom_css: "کد CSS اضافی",
    custom_js: "کد Java Script اضافی",
    custom_head: "کد اضافه در <head> سایت",
    custom_body: "کد اضافه در <body> سایت",
    custom_code: "کد اضافی",
    siteContactInformation: "اطلاعات تماس سایت",
    moreInfo: "اطلاعات بیشتر",
    makeCategory: "ایجاد دسته‌بندی",
    editCategory: "ویرایش دسته‌بندی",
    categoryName: "نام دسته",
    categoryParentName: "دسته والد",
    categories: "دسته‌بندی‌ها",
    pleaseChooseCategoriesFirst: "لطفا ابتدا دسته‌بندی را انتخاب کنید!",
    category: "دسته‌بندی‌",
    chooseSubCategory: "انتخاب زیر دسته‌",
    chooseMainCategory: "انتخاب دسته‌ اصلی",
    removeImage: 'حذف تصویر',
    viewCategory: 'مشاهده دسته‌بندی',
    username: "نام کاربری",
    userName: "نام و نام‌خانوادگی",
    confirmationStatus: "تایید اطلاعات",
    registerDate: "تاریخ ثبت‌نام",
    changeRole: "تغییر سطح دسترسی",
    role: "سطح دسترسی",
    allRoles: "تمام سطوح دسترسی",
    exportUsersList: "دانلود لیست کاربران",
    addUser: "افزودن کاربر",
    editUser: "ویرایش کاربر",
    editDetails: "ویرایش پروفایل",
    dataEdited: "اطلاعات شما ویرایش شد",
    dataEditingFailed: "در ویرایش اطلاعات مشکلی به وجود آمده است",
    deleteData: "اطلاعات حذف شد",
    deleteDataFailed: "در حذف این اطلاعات مشکلی به وجود آمده است",
    admin: "مدیر سایت",
    user: "کاربر",
    supportUser: "پشتیبانی",
    product_manager: "ادمین محصولات",
    support: "پشتیبانی",
    adminType: "مدیران سایت",
    normalType: "سایر کاربران",
    usersLog: "فعالیت‌های کاربران",
    saveCode: "ذخیره کد",
    writeYourCodehere: "کد خود را اینجا بنویسید",
    customCodeTitle: "کد‌های مورد نیاز خود را اضافه کنید!",
    customCodeText: "در این قسمت می‌توانید کد‌های مورد نیاز خود را در بخش‌های مختلف سایتتان اضافه و آن‌ها را سفارشی‌سازی کنید!",
    main_phone: "شماره تلفن سایت",
    main_email: "ایمیل سایت",
    main_mobile: "شماره موبایل سایت",
    main_address: "آدرس",
    facebook: "لینک Facebook",
    linkedin: "لینک LinkedIn",
    pinterest: "لینک Pinterest",
    instagram: "لینک Instagram",
    twitter: "لینک Twitter",
    whatsapp: "لینک Whatsapp",
    telegram: "لینک Telegram",
    headerAndFooter: "مدیریت هدر و فوتر",
    short_description: "توضیح کوتاه سایت",
    footer_text: "متن توضیح فوتر",
    footer_certificates: "کد نماد‌های فوتر",
    map_script: "کد نقشه صفحه تماس",
    bg_image: "تصویر پس‌زمینه",
    gradientBg: "پس‌زمینه گردینت",
    solidBg: "پس‌زمینه رنگ ساده",
    imageBg: "پس‌زمینه با تصویر",
    gradientBgFirstColor: "رنگ اول گردینت",
    gradientBgSecondColor: "رنگ دوم گردینت",
    bg_type: "نوع تصویر پس زمینه",
    slideShowImages: "المان‌های تصویری",
    SlideShowContent: "محتوای اسلایدشو",
    nextStep: "بخش بعدی",
    mainImageSlide: "تصویر اصلی اسلاید",
    contactInformation: "اطلاعات تماس سایت",
    footerCertificationTitle: "کد مربوط به نماد ها",
    footerCertificationText: "در این قسمت می‌توانید کد‌های دریافتی از سازمان های مختلف مانند نماد اعتماد، رسانه‌های دیجیتال و... را قرار دهید.",
    mapScriptTitle: "کد نقشه",
    mapScriptText: "در این قسمت می‌توانید کد‌های دریافتی از پلتفرم های مختلف نمایش نقشه مانند Google Map را قرار دهید، تا در صفحه تماس نشان داده شوند",
    saveChanges: "ذخیره تغییرات",
    adminDashboard: "داشبورد مدیریت",
    userDashboard: "داشبورد کاربر",
    currencies: "واحد‌های پولی",
    addContactInformation: "اطلاعات تماس ویترین",
    phoneNumber: "شماره تماس",
    addPhoneNumber: "افزودن شماره",
    addEmail: "افزودن ایمیل",
    relatedLinks: "لینک‌های مرتبط",
    websiteLink: "لینک وب‌سایت",
    facebookLink: "لینک فیسبوک",
    whatsappLink: "شماره واتس‌اپ",
    telegramLink: "لینک تلگرام",
    instagramLink: "لینک اینستاگرام",
    twitterLink: "لینک توئیتر",
    websitePlaceHolder: "https://yourwebsite.com",
    facebookPlaceHolder: "https://facebook.com/your_page",
    whatsappPlaceHolder: "+9712345678",
    telegramPlaceHolder: "https://t.me/your_number",
    instagramPlaceHolder: "https://instagram.com/your_page",
    twitterPlaceHolder: "https://twitter.com/your_page",
    phoneIsWrong: "شماره تلفن اشتباه است",
    youtube: "یوتیوب",
    addFromYoutube: "افزودن ویدیو از یوتیوب",
    addYoutubeLinkHere: "لطفا یک لینک ویدیو یوتیوب در فیلد پایین بنویسید!",
    addVideo: "افزودن ویدیو",
    youtubeLink: "لینک ویدیو از یوتیوب",
    back: "بازگشت",
    wrongURlForYoutube: "لینک اشتباه است!",
    toggleDark: "حالت شب",
    toggleLight: "حالت روز",
    addMainDetails: "اطلاعات اصلی ویترین",
    addImages: "تصاویر ویترین",
    primaryAdImage: "تصویر اصلی ویترین",
    savedFiles: "فایل‌های آپلود شده",
    fileSize20MB: "حجم فایل باید کمتر از 20MB باشد!",
    fileSize10mb: "حجم فایل باید کمتر از 10MB باشد!",
    fileSize100mb: "حجم فایل باید کمتر از 100MB باشد!",
    addPreviewToVideo: "افزودن پیش‌نمایش به ویدیو",
    doYouWantToAddThumbnail: "آیا قصد افرودن تصویر پیش‌نمایش به این ویدیو را دارید؟",
    addPreviewImage: "افزودن پیش‌نمایش",
    addVideoWithoutPreview: "پیش‌نمایش نمی‌خواهم",
    adsGallery: "گالری ویترین",
    removeItem: "حذف آیتم",
    addItem: "افزودن آیتم",
    address: "آدرس",
    addressAndLocation: "آدرس و لوکیشن",
    country: "کشور",
    state: "استان / ایالت",
    yourLocation: "موقعیت شما",
    searchInMap: "جستجو در نقشه",
    adAdded: "ویترین شما ایجاد شد",
    adAddingFailed: "در ایجاد ویترین مشکلی به وجود آمده است",
    adEdited: "ویترین شما ویرایش شد",
    adEditingFailed: "در ویرایش ویترین مشکلی به وجود آمده است",
    commentEdited: "کامنت ویرایش شد",
    commentAdded: "کامنت ایجاد شد",
    commentAddingFailed: "افزودن کامنت با مشکل مواجه شد",
    commentEditingFailed: "ویرایش کامنت با مشکل مواجه شد",
    commentRemoved: "کامنت با موفقیت حذف شد",
    rating: "امتیاز",
    comment: "دیدگاه",
    comments: "دیدگاه‌ها",
    editComment: "ویرایش دیدگاه",
    addComment: "افزودن دیدگاه",
    chooseAdForComment: "انتخاب ویترین برای کامنت",
    noAds: "ویترین یافت نشد!",
    commentForReplayDescription: "اگر قصد پاسخ به کامنتی را دارید از اینجا انتخاب کنید",
    parentCommentDesc: "شما درحال پاسخ دادن به کامنت زیر هستید:",
    site_logo: "لوگو سایت",
    favicon: "نمادک سایت (FavIcon)",
    square_logo: "لوگو مربع",
    page_header: "هدر صفحات داخلی",
    pagination_count: "تعداد آیتم در هر صفحه",
    page_count: "تعداد صفحه برای لود Ajax",
    app_name: "نام وب سایت",
    youtubeChanel: "لینک کانال یوتیوب",
    youtubePlaceHolder: "https://www.youtube.com/channel/your_chanel",
    viewPreview: "پیش نمایش",
    userLikes: "تعداد لایک کاربران",
    adInfo: "اطلاعات ویترین",
    viewAd: "مشاهده ویترین",
    pleaseChooseYourLocation: "لطفا موقعیت خود را مشخص کنید!",
    fileInUse: "این فایل درحال استفاده است!",
    backToHome: "بازگشت",
    basic_plan: "پلن پایه",
    bronze_plan: "پلن برنزی",
    silver_plan: "پلن سیلور",
    gold_plan: "پلن گلد",
    planInfo: "اطلاعات پلن",
    yourPlan: "پلن شما",
    months: "ماه",
    adValidityPeriod: "مدت زمان اعتبار ویترین",
    monthlyAdCount: "تعداد ویترین قابل ایجاد",
    monthlyEditCount: "تعداد ویرایش قابل انجام",
    viewPricing: "مشاهده تعرفه‌ها",
    youCantAddAd: "شما امکان افزودن ویترین جدید ندارید!",
    youCantEditAd: "شما امکان ویرایش ویترین ندارید!",
    planLimitationDescription: "با توجه به پلن شما امکان انجام این کار وجود ندارد، لطفا تعرفه‌هارا مشاهده نمایید و پلن مناسب خود را تهیه کنید!",
    expired_at: "تا انقضای ویترین",
    yourPlanDoesntSupportThis: "پلن شما این مورد را پشتیبانی نمی‌کند",
    yourPlanDoesntSupportThisDesc: "برای استفاده از این ویژگی پلن خود را ارتقا دهید",
    manageFields: "مدیریت فیلد‌ها",
    fieldName: "نام فارسی فیلد",
    addField: "افزودن فیلد",
    makeField: "ایجاد فیلد",
    adminHasFullAccess: "ادمین دسترسی کامل دارد!",
    pleaseAdCategoryFirst: "لطفا ابتدا دسته‌بندی را انتخاب کنید!",
    specificFields: "فیلد‌های اختصاصی",
    totalVisitsCount: "تعداد کل بازدید",
    todayVisitsCount: "تعداد بازدید امروز",
    totalCommentsCount: "تعداد دیدگاه ها",
    noLogFound: "نتیجه‌ای یافت نشد",
    allowed_comments: "امکان ارسال کامنت",
    allowed: "مجاز",
    not_allowed: "غیرمجاز",
    expired: "منقضی شده",
    equalItem: "معادل انگلیسی",
    addEqual: "افزودن معادل",
    noEqual: "محتوای معادلی وجود ندارد!",
    emptyTableCells: "جدول خالی است!",
    AddTable: "ساخت جدول",
    EditTable: "ویرایش جدول",
    TableBuilder: "جدول ساز",
    tableName: "نام جدول",
    makeTable: "ساخت جدول",
    chooseTableStructure: "تعداد ردیف و ستون جدول خود را مشخص نمایید!",
    columns: "ستون‌ها",
    rows: "ردیف‌ها",
    addRows: "افزودن ردیف",
    addColumns: "افزودن ستون",
    buildTable: "ساخت جدول",
    withHeading: "جدول همراه با سربرگ",
    withoutHeading: "جدول بودن سربرگ",
    tableTdPlaceholder: "محتوای خود را بنویسید...",
    shortcode: "کد کوتاه",
    insertTable: "درج جدول",
    manageCategoriesOrder: "مدیریت ترتیب دسته‌بندی‌ها",
    editSubMenuOrder: "ترتیب زیر دسته",
    noCategoryFound: "دسته‌ای یافت نشد!",
    youAreEditingParentCategoriesOrder: "شما درحال مرتب‌سازی دسته‌بندی‌های اصلی هستید!",
    youAreEditingCategoriesOrder: "درحال مرتب‌سازی زیر دسته:‌ ",
    youAreEditingParentCategoriesOrderDesc: "با کلیک روی دکمه ویرایش زیرمجموعه هر دسته را مرتب کنید",
    update: "بروزرسانی",
    updateAdReal: "بروزرسانی ویترین",
    updateAdRealDesc: "شما می‌توانید ویترین خود را بروز کنید تا در لیست ویترین‌ها در موقعیت بالاتری قرار بگیرد!",
    advertisementUpdated: "ویترین بروز شد!",
    rate: "امتیاز",
    myBusiness: "کسب‌و‌کار من",
    businessAdded: "کسب‌و‌کار شما ایجاد شد",
    businessAddingFailed: "در ایجاد کسب‌و‌کار مشکلی به وجود آمده است",
    businessEdited: "کسب‌و‌کار شما ویرایش شد",
    businessEditingFailed: "در ویرایش کسب‌و‌کار مشکلی به وجود آمده است",
    editBusiness: "ویرایش کسب‌و‌کار",
    makeBusiness: "ایجاد کسب‌و‌کار",
    businessName: "نام کسب‌و‌کار",
    businesses: "کسب‌و‌کارها",
    business: "کسب‌و‌کار",
    businessesList: "لیست کسب‌و‌کارها",
    viewBusiness: "مشاهده کسب‌و‌کار",
    businessLogo: "لوگو کسب‌و‌کار",
    yourBusinessIsActive: "تبریک! کسب‌و‌کار شما تایید شده و فعال است",
    yourBusinessIsInchecking: "کسب‌و‌کار شما در دست بررسی است!",
    businessBanners: "بنر‌های کسب‌و‌کار",
    foundingYear: "سال تاسیس",
    businessVideos: "ویدیو‌ها",
    manageBusinesses: "مدیریت کسب‌وکار‌ها",
    businessBio: "توضیح کوتاه کسب‌وکار‌",
    primaryBusinessImage: "تصویر اصلی کسب‌وکار",
    youCantAddBusiness: "امکان افزودن کسب‌و‌کار وجود ندارد!",
    pageInfo: "اطلاعات صفحه",
    remove: "حذف",
    deleteIt: "پاک شود",
    active: "فعال",
    activeStatus: "وضعیت",
    addSlideShow: "افزودن اسلایدشو",
    chooseAdForSlide: "انتخاب ویترین برای اسلاید",
    banner_image: "تصویر بنر",
    addBanner: "افزودن بنر",
    editBanner: "ویرایش بنر",
    pageTitle: "تیتر صفحه",
    addPage: "افزودن صفحه",
    makePage: "ایجاد صفحه",
    editPage: "ویرایش صفحه",
    viewPage: "مشاهده صفحه",
    youCantDeletePermanentPages: "امکان حذف صفحات اصلی وجود ندارد!",
    manageFAQ: "پرسش‌های متداول",
    addFAQ: "افزودن پرسش و پاسخ",
    editFAQ: "ویرایش پرسش و پاسخ",
    question: "پرسش",
    answer: "پاسخ",
    editDiscount: "ویرایش تخفیف",
    makeDiscount: "ایجاد تخفیف",
    discountName: "تیتر تخفیف",
    discounts: "تخفیف‌ها",
    discount: "تخفیف",
    discountsList: "لیست تخفیف‌ها",
    viewDiscount: "مشاهده تخفیف",
    manageDiscounts: "مدیریت تخفیف‌ها",
    myDiscounts: "تخفیف‌های من",
    discountAdded: "تخفیف شما ایجاد شد",
    discountAddingFailed: "در ایجاد تخفیف مشکلی به وجود آمده است",
    discountEdited: "تخفیف شما ویرایش شد",
    discountEditingFailed: "در ویرایش تخفیف مشکلی به وجود آمده است",
    deleteDiscount: "تخفیف حذف شد!",
    deleteDiscountFailed: "در حذف تخفیف مشکلی به وجود آمده است!",
    discountInfo: "اطلاعات تخفیف",
    primaryDiscountImage: "تصویر اصلی تخفیف",
    discountGallery: "گالری تصاویر",
    discountDescription: "اطلاعات تخفیف",
    requestToDeleteDiscount: "درخواست حذف تخفیف",
    removeDiscount: "حذف تخفیف",
    choose: "انتخاب",
    discount_percent: "درصد تخفیف",
    priceDiscount: "مبلغ واقعی ",
    discount_price: "مبلغ با احتساب تخفیف",
    inventory: "تعداد کد تخفیف",
    discount_expire_time: "زمان انقضای تخفیف",
    dateShouldBeAfterToday: "زمان اشتباه است!",
    discount_expired_at: "تا انقضای تخفیف",
    manageDiscountsSlider: "اسلایدر تخفیفات",
    bannerTitle: "تیتر بنر",
    wrongPercent: "درصد اشتباه",
    discount_banner_1: "بنر ۱ صفحه تخفیف تکی",
    discount_banner_2: "بنر ۲ صفحه تخفیف تکی",
    bannerPosition: "موقعیت بنر",
    sliderTitle: "تیتر اسلاید",
    primary_button_text:'متن دکمه اصلی',
    primary_button_icon:'آیکون دکمه اصلی',
    primary_button_link:"لینک دکمه اصلی",
    manageAdsComments:"دیدگاه ویترین‌ها",
    manageBusinessesComments:"دیدگاه کسب‌و‌کار‌ها",
    manageDiscountsComments:"دیدگاه تخفیفات",
    ad:"ویترین",
    chooseItemForComment:"انتخاب آیتم برای کامنت",
    noItem:"آیتمی یافت نشد!",
    term_of_use:"شرایط استفاده",
    articles:"فیل مگ",
    manageContents:"مدیریت فیل مگ",
    contentsList:"لیست مقالات",
    makeContent:"ایجاد مقاله",
    manageBlogCategories:"دسته‌بندی‌ مقالات",
    manageBlogComments:"مدیریت دیدگاه‌ها",
    contents: "فیل مگ",
    addContent: "ایجاد مقاله جدید",
    viewContent: "مشاهده مقاله‌های قبلی",
    editContent: "ویرایش مقاله",
    contentTitle: "تیتر مقاله",
    published: "منتشر شده",
    draft: "پیش‌نویس",
    publishStatus: "وضعیت انتشار",
    viewArticle: "مشاهده مقاله",
    relatedAds: "ویترین‌های مرتبط",
    chooseAd: "انتخاب ویترین",
    addAd: "افزودن ویترین",
    tickets: "تیکت پشتیبانی",
    manageMessages: "مدیریت پیام‌ها",
    manageTickets: "مدیریت تیکت‌ها",
    manageDepartments: "مدیریت دپارتمان‌ها",
    requestType: "نوع درخواست",
    supportRequestFromDepartment: "پشتیبانی سیستم",
    supportRequestForProduct: "پشتیبانی محصول",
    sendFile: "ارسال فایل",
    ticket_sender: "ارسال کننده",
    ticket_receiver: "دریافت کننده",
    receiver: "دریافت کننده",
    ticket_title: "موضوع",
    ticket_status: "وضعیت",
    ticket_edited_at: " آخرین ارسال",
    sendMessage: "ارسال پیام",
    backToTickets: "بازگشت به تیکت‌ها",
    deleteTicket: "تیکت حذف شد!",
    deleteTicketFailed: "حذف تیکت با مشکل مواجه شد!",
    ticket_details: "اطلاعات تیکت",
    ticket_sent: "تیکت ارسال شد!",
    sendFastTicket: "ارسال سریع پیام",
    sendMessageTo: "ارسال پیام به",
    addTicket: "ارسال تیکت",
    send: "ارسال",
    messageText: "متن پیام",
    gotToTicketPage: "صفحه پیام‌ها",
    removeRequest: 'حذف درخواست',
    related_admin: "ادمین مربوطه",
    department: "دپارتمان",
    departments: "دپارتمان‌ها",
    low: "کم",
    medium: "متوسط",
    high: "زیاد",
    subject: "موضوع",
    message: "پیام",
    priority: "اولویت",
    unread: "در انتظار بررسی",
    read: "خوانده شده",
    sentTicket: "ارسال شده",
    sent: "ارسال شده",
    referToMe: "ارجاع شده به من",
    resumeOrder: "ادامه سفارش",
    bill:"صورت حساب",
    checkout: "تکمیل سفارش",
    totalPrice:"قیمت کل",
    productsInCart: "آیتم‌های موجود در سبد خرید",
    totalCartPrice:"جمع سبد خرید",
    totalUserDiscounts:"جمع تخفیف‌ها",
    totalDiscounts:"سود شما از خرید",
    makeOrder:"ثبت سفارش",
    orderPayed:"مبلغ سفارش پرداخت شد",
    orderPayingFailed:"در پرداخت مبلغ مشکلی وجود دارد!",
    pending:"در انتظار تایید",
    not_payed:"در انتظار پرداخت",
    viewOrders: "مشاهده سفارش‌ها",
    viewOrdersDetails: "همه سفارش‌های گذشته خود را از این بخش مشاهده نمایید!",
    viewOrder: "مشاهده سفارش‌",
    orders: "سفارش‌ها",
    ordersList: "لیست سفارشات من",
    orderDetails: "جزئیات سفارش‌",
    productsList: "آیتم‌های خریداری شده",
    purchase: "پرداخت",
    payableMoney: "مبلغ قابل پرداخت",
    payOrder: "پرداخت سفارش‌",
    order_status:"وضعیت سفارش",
    factor_number:"شماره فاکتور",
    factor_status:"وضعیت فاکتور",
    created_at_factor: "تاریخ ثبت سفارش",
    mobileNumber: "شماره موبایل",
    status: "وضعیت",
    payed: "پرداخت شده",
    not_used: "استفاده نشده",
    used: "استفاده شده",
    voucher: "ووچر",
    voucher_expired_at: "زمان انقضا",
    getVoucher: "دریافت ووچر",
    unique_key: "کد یکتا",
    not_use: "استفاده نشده",
    withoutExpire: "بدون انقضا",
    manageOrders: "مدیریت سفارش‌ها",
    dataRemoved: "اطلاعات با موفقیت حذف شد",
    dataRemovingFailed: "حذف اطلاعات با مشکل رو به رو شد",
    buyer: "خریدار",
    purchasedVouchers: "تخفیفات فروخته شده",
    singular_price: "قیمت واحد",
    viewVouchers: "مشاهده ووچرها",
    viewVoucher: "مشاهده ووچر",
    doesntHave: "ندارد",
    dateShouldBeAfterExpire: "مهلت استفاده باید بعد از زمان انقضا باشد",
    expire_after_purchase: "مهلت استفاده ",
    fieldEnglishName: "نام انگلیسی فیلد",
    fieldDescription: "توضیحات فارسی فیلد",
    fieldDescriptionEnglish: "توضیحات انگلیسی فیلد",
    editField: "ویرایش فیلد",
    businessFields:"فیلد‌های کسب‌وکار",
    advertisementFields:"فیلد‌های ویترین",
    discountFields:"فیلد‌های تخفیف",
    globalFields:"فیلد‌های کلی",
    fields:"فیلد‌ها",
    uniqueKey:"Unique key",
    isThisFieldRequired:"فیلد ضروری!",
    field_type:"نوع فیلد",
    text:"متن",
    long_text:"متن طولانی",
    select_field:"فیلد انتخابی",
    multi_select_field:"فیلد چند انتخابی",
    options:"گزینه‌های فارسی",
    english_options:"گزینه‌های انگلیسی",
    pressEnterToMakeEachOption:"برای ایجاد هر گزینه اینتر را بزنید!",
    units:"واحد‌های فارسی",
    english_units:"واحد‌های انگلیسی",
    pleaseChooseACategoryForFields:"در صورت نیاز می‌توانید دسته‌بندی مورد نظر خود را انتخاب و فیلد‌های اختصاصی آن را ایجاد کنید",
    pleasChooseCategoryFirst:"لطفا ابتدا دسته‌بندی مورد نظر خود را انتخاب کنید",
    total_employees:"تعداد کارکنان",
    certificates:"استاندارد‌ها",
    certificate:"استاندارد‌",
    addCertificate:"افزودن استاندارد‌",
    editCertificate:"ویرایش استاندارد‌",
    aboutBusiness:"درباره کسب‌وکار‌",
    equalCategory:"معادل انگلیسی",
    openControl:"ادیتور پیشرفته‌",
    standards:"استاندارد‌ها‌",
    standardsPlaceHolder:"ISO:9002 ...",
    relatedDiscount:"تخفیف مرتبط",
    relatedDiscountDesc:"در صورتی که تخفیفی مرتبط با این ویترین دارید آن را اتتخاب کنید تا در صفحه ویترین نمایش داده شود",
    chooseADiscount:"یک تخفیف را انتخاب کنید",
    chosenDiscount:"تخفیف انتخاب شده",
    iconsList:"لیست آیکون‌‌ها",
    changeMobileOrEmail:"تغییر شماره موبایل یا ایمیل",
    changeMobileOrEmailDesc:"در صورت تغییر اطلاعات، نیاز به تایید مجدد می‌باشد",
    anotherUserHasThisInfo:"اطلاعات وارد شده قبلا توسط کاربر دیگری ثبت شده است",
    vouchersList:"لیست ووچر‌ها",
    newsletterSubscribers:"اعضای خبرنامه",
    hoverImage:"تصویر هاور",
    isThisAdBest:"آیا در برترین ویترین‌ها نمایش داده شود؟ ",
    isThisDiscountBest:"آیا در تخفیفات ویژه فیلی نمایش داده شود؟ ",
    isVerifiedDescription:"آیا این کسب و کار تایید شده است؟ ",
    isDiscountProviderDescription:"آیا این کسب و کار ارائه دهنده تخفیف است؟ ",
    imageProperWidthHeight:"طول و عرض مناسب ",
    imageProperSize:"حداکثر سایز تصاویر ",
    videoProperSize:"حداکثر سایز ویدیو ",
    createBusiness:"ایجاد کسب‌وکار ",
    relatedUser:"کاربر مربوطه",
    chooseAUser:"یک کاربر را انتخاب کنید!",
    relatedUserBusiness:"کاربر / کسب‌و‌کار مربوطه",
    inCaseOfNotChoosingUser:"در صورت عدم انتخاب کاربر، ویترین برای شما ثبت می‌شود",
    pleaseCompleteYourInfoFirst:"لطفا ابتدا اطلاعات حساب کاربری خود را تکمیل نمایید!",
    readBusinessLocation:"همخوانی از لوکیشن کسب‌و‌کار",
    inCaseOfNotChoosingUserDiscount:"در صورت عدم انتخاب کاربر، تخفیف برای شما ثبت می‌شود",
    youCantAddDiscount:"امکان ایجاد تخفیف وجود ندارد",
    youCantEditDiscount:"امکان ویرایش تخفیف وجود ندارد",
    planLimitationDescriptionDiscount: "با توجه به پلن شما امکان انجام این کار وجود ندارد، لطفا تعرفه‌هارا مشاهده نمایید و پلن مناسب خود را تهیه کنید!",
    makeVouchers: "ایجاد ووچر",
    typeOfDiscount: "انتخاب نحوه ایجاد کد تخفیف",
    discountBySystem: "ایجاد توسط سیستم",
    discountByManual: "وارد کردن دستی",
    makeDiscountCodes: "ساخت کد‌های تخفیف",
    discountCode: "کد تخفیف",
    pleaseFillFiledBefore: "لطفا فید قبلی را تکمیل نمایید",
    importFromExcel: "وارد کردن از اکسل",
    wrongFormat: "فرمت انتخاب شده اشتباه است!",
    makeBlogCategory: "ایجاد دسته‌بندی مقالات",
    editBlogCategory: "ویرایش دسته‌بندی مقالات",
    menuImage: "تصویر داخل صفحه",
    changeColor: "تغیر رنگ",
    bg_color: "رنگ پس‌زمینه",
    main_color: "رنگ اصلی",
    content_type: "نوع محتوا",
    article: "مقاله",
    tricks: "ترفند ها",
    bests: "برترین ها",
    attractions: "دیدنی ها",
    reviews: "نقد و بررسی",
    none: "هیچکدام",
    articlesGallery: "گالری مقالات",
    relatedBusinesses: "کسب‌و‌کار‌های مرتبط",
    chooseBusiness: "انتخاب کسب‌وکار",
    addBusiness: "افزودن کسب‌وکار",
    keywordsLabel: "عبارات کلیدی",
    allKeywordsAreTyped: "امکان افزودن کلمات کلیدی بیشتر ندارید ",
    keywordsDescription: "تگ‌های مرتبط را بنویسید",
    pressEnterAfterEachKeyword:"پس از نوشتن هر عبارت دکمه اینتر را بزنید!",
    is_featured_article:"آیا این مقاله جزو مقالات برتر است؟",
    news:"فیل نیوز",
    manageNews:"مدیریت اخبار",
    newsList:"لیست اخبار",
    makeSingleNews:"ایجاد خبر",
    manageNewsCategories:"دسته‌بندی‌ اخبار",
    addSingleNews: "ایجاد خبر جدید",
    viewSingleNews: "مشاهده خبر",
    editSingleNews: "ویرایش خبر",
    singleNewsTitle: "تیتر خبر",
    makeNewsCategory: "ایجاد دسته‌بندی اخبار",
    editNewsCategory: "ویرایش دسته‌بندی اخبار",
    newsGallery: "گالری اخبار",
    news_expiration: "تعداد روز انقضا اخبار",
    ads_expiration: "تعداد روز انقضا ویترین",
    shouldNewsExpire: "خبر دارای تاریخ انقضا است",
    source: "منبع",
    source_url: "لینک منبع",
    manageArticlesComments: "دیدگاه مقالات",
    manageNewsComments: "دیدگاه اخبار",
    content: "مقاله",
    step1: "ثبت‌نام اولیه",
    step2: "تکمیل فرم",
    step3: "آپلود مدارک",
    step4: "در انتظار تایید",
    step5: "نیاز به اصلاح",
    step6: "تایید",
    brand_name: "نام برند",
    license_number: "شماره لایسنس",
    license_expiration_date: "تاریخ انقضای لایسنس",
    companyName: "نام شرکت",
    saveAndContinue: "ثبت و ادامه",
    pleaseConfirm: "لطفا تایید بفرمایید",
    withRegisteringAnAccount: "با ایجاد حساب کاربری، با ",
    and: " و ",
    termsAndConditions: "قوانین و مقررات",
    termsAndConditionsBusiness: "قوانین و مقررات کسب و کار",
    tradeLicense: "Trade License",
    tradeLicenseContract: "Trade License/Contract",
    emiratesCard: "Emirates Card",
    pleaseChooseAFile: "لطفا یک فایل انتخاب نمایید",
    yourInfoIsUnderChecking: "اطلاعات شما درحال بررسی می‌باشد",
    yourInfoIsUnderCheckingDesc: "پس از بررسی توسط کارشناسان سایت، نتیجه ثبت‌نام، به شما اطلاع رسانی‌ خواهد شد",
    confirmRegisterInfo: "تایید اطلاعات ثبت‌نام",
    rejectRegisterInfo: "عدم تایید اطلاعات",
    areInfosConfirmed: "اطلاعات ثبت نام کاربر مورد تاییداند",
    faults: "ایرادات فرم ثبت نام",
    registerInfo: "اطلاعات ثبت‌نام",
    registerInfoNeedApprovalTitle: "بررسی اطلاعات ثبت‌نام",
    registerInfoNeedApproval: "اطلاعات ثبت‌نام کاربر نیاز به بررسی دارد، لطفا موارد را بررسی و تایید و یا رد نمایید",
    yourInfoIsVerified: "اطلاعات ثبت‌نام شما با موفقیت تایید شد",
    yourInfoIsVerifiedDesc1: "اطلاعات شما تایید و پلن ",
    yourInfoIsVerifiedDesc2: " به شما تخصیص داده شد",
    yourInfoIsChecked: "اطلاعات ثبت‌نام شما بررسی شد",
    pleaseReadAndDo: "لطفا موارد زیر را مطالعه کرده و اطلاعات خود را ویرایش نمایید",
    editInfo: "ویرایش اطلاعات",
    managePlanParents: "گروه‌بندی پلن‌ها",
    plans: "پلن‌ها",
    makePlanParent: "ایجاد گروه پلن",
    planName: "نام پلن",
    planEnglishName: "نام پلن انگلیسی",
    description: "توضیحات",
    english_description: "توضیحات انگلیسی",
    editParent: "ویرایش گروه پلن",
    makeParent: "ایجاد  گروه پلن",
    planFeatures: "ویژگی‌های پلن",
    planEnglishFeatures: "ویژگی‌های انگلیسی پلن",
    managePlans: "مدیریت پلن‌ها",
    days: "روز",
    editPlan: "ویرایش پلن",
    makePlan: "ایجاد پلن",
    planParent: "گروه پلن",
    discountPrice: "قیمت با تخفیف",
    durationPlan: "مدت اعتبار",
    ad_count: "تعداد ویترین رایگان",
    edit_count: "تعداد ویرایش و بروزرسانی",
    photography: "تعداد عکس تبلیغاتی",
    verify_badge: "تیک وریفای",
    purchase_ad: "امکان خرید ویترین",
    has_business_page: "صفحه کسب و کار با قابلیت های ویژه",
    has_discount: "امکان ایجاد تخفیف",
    has_teaser: "ساخت تیزر تبلیغاتی",
    has_introducing_video: "ساخت ویدئو معرفی",
    philmag: "فیل مگ اختصاصی",
    has_banner_design: "طراحی بنر اختصاصی برای صفحه کسب و کار",
    has_commercial_banner: "درج بنر اختصاصی در قسمت های مختلف سایت",
    has_link: "لینک به صفحات اجتماعی",
    has_support: "سیستم چت و پشتیبانی 24 ساعته",
    has_distance: "نمایش فاصله زمانی ویترین/تخفیف تا ویزیتور",
    free: "رایگان",
    discountMoreThanPrice: "مبلغ تخفیف از مبلغ اصلی بیشتر است",
    pageCover: "بنر صفحه",
    color: "رنگ",
    faq_categories: "دسته‌بندی سوالات متداول",
    FAQCategory: "دسته‌بندی",
    purchase_ads_page: "صفحه سفارش ویترین و پلن",
    page_title: "تیتر صفحه",
    english_page_title: "تیتر صفحه انگلیسی",
    buyAd: "خرید ویترین",
    month: "ماه",
    registerBusiness: "ثبت‌نام‌ کسب‌وکار",
    planValidity: "اعتبار تا تاریخ",
    viewPlans: "مشاهده پلن‌ها",
    has_ad: "امکان ایجاد ویترین",
    expirationDate: "تاریخ انقضا",
    renew: "تمدید",
    renewAd: "تمدید ویترین منقضی شده",
    renewAdDesc: "شما می‌توانید ویترین  منقضی شده خود را بروز کنید تا مجدد در لیست ویترین‌ها قرار بگیرد!",
    monthlyAdRenewCount: "تعداد ویترین قابل ایجاد / تمدید",
    myPlan: "پلن من",
    reserved_plan: "پلن رزرو",
    planDuration: "مدت زمان پلن",
    staringDate: "زمان شروع",
    voucherCode: "کد ووچر",
    addMoreField: "افزودن کد بیشتر",
    pleaseAddSomeCodes: "لطفا کدهای ووچر تخفیف خود را وارد کنید",
    wrongVoucherCode: "کد ووچر اشتباه است",
    someVoucherCodesAreDuplicated: "تعدادی از کد‌ها تکراری هستند، لطفا آن‌هارا اصلاح نمایید",
    manageVouchers: "مدیریت ووچر‌ها",
    availableVouchers: "ووچر‌های موجود",
    addMoreVouchers: "افزودن ووچر‌های بیشتر",
    not_purchased: "خریداری نشده",
    voucherTypeSystem: "ایجاد کد ووچر توسط سیستم",
    voucherTypeSystemDesc: "در این حالت شما فقط تعداد کد‌های تخفیف قابل فروش خود را مشخص می‌کنید و سیستم‌ این‌ کد‌هارا برای شما تولید می‌کند",
    voucherTypeManual: "ایجاد کد‌ ووچر توسط شما",
    voucherTypeManualDesc: "در این حالت شما کد‌های ووچر تخفیف‌هارا خودتان وارد می‌کنید و به تعداد کدی که وارد می‌کنید، امکان فروش تخفیف‌ خواهید داشت",
    voucherTypeManualDescStrong: "کد‌های ووچر باید فقط شامل عدد و حروف و حداقل ۵ کاراکتر باشند",
    forPurchasingBetterPlansAdsAndReserveGoToPlans: "برای خرید پلن بالاتر، تمدید پلن و خرید ویترین به صفحه خرید مراجعه نمایید",
    plansPage: "مشاهده صفحه",
    yourDiscountIsActive: "تبریک! تخفیف شما تایید شده و فعال است",
    yourDiscountIsInchecking: "تخفیف شما در دست بررسی است!",
    makeVouchersForEqual: "ایجاد کد‌های ووچر برای معادل انگلیسی",
    voucherEn: "Voucher",
    getAllVouchers: "دریافت همه ووچر‌ها",
    vouchersInfo: "اطلاعات ووچر‌ها",
    total_vouchers: "تعداد کل ووچر‌ها",
    purchased_vouchers: "ووچر‌های فروخته شده",
    not_purchased_vouchers: "ووچر‌های باقی‌مانده",
    verificationDescription: "جهت ارسال درخواست واریز نیاز به تایید اطلاعات حساب بانکی دارید",
    verificationDetails: "اطلاعات حساب بانکی",
    verificationDataSets: "اطلاعات حساب بانکی ثبت شد!",
    manageVerifications: "مدیریت اطلاعات بانکی",
    ManageUserVerification: "مدیریت اطلاعات حساب بانکی کاربر",
    viewUserBankInfo: "مشاهده اطلاعات حساب بانکی کاربر",
    yourBankInfoIsActive: "اطلاعات حساب بانکی شما تایید شده و امکان درخواست واریز برای شما فراهم است",
    yourBankInfoIsInchecking: "اطلاعات حساب بانکی شما در دست بررسی است",
    payment_type: "نوع حساب بانکی",
    credit_number: "Credit card number",
    paypal_username: "Paypal Username",
    paypal: "Paypal",
    credit_card: "Credit Card",
    viewRegisterInfo: "مشاهده اطلاعات ثبت‌نام",
    viewRegisterInfoHint: "شما می‌توانید اطلاعات ثبت‌نام خود را در این بخش مشاهده نمایید در صورت نیاز به تغییر اطلاعات تیکت ثبت نمایید",
    submitTicket: "ثبت تیکت",
    buyersOrdersList: "لیست سفارشات مشتریان",
    manageWallets: "مدیریت تراکنش‌ها",
    paymentManagement: "مدیریت پرداخت‌ها",
    amount: "مبلغ",
    transactionType:"نوع تراکنش",
    withdrawMoney:"درخواست واریز وجه",
    withdrawMoneyDescription:"مقدار مورد نظر جهت واریز را در این قسمت وارد کنید",
    deposit:"شارژ",
    withdraw:"برداشت",
    transactionTrackingCode:"کد رهگیری تراکنش",
    addRefrenceIdFirst: "ابتدا کد رهگیری تراکنش را وارد کنید!",
    balance: "موجودی",
    manageUserWallet: "مدیریت تراکنش‌های کاربر",
    myTransactions: "تراکنش‌های من",
    youHavePendingWithdrawRequest: "شما یک درخواست در انتظار بررسی دارید!",
    wrongPrice: "مبلغ اشتباه است",
    cantWithdrawSmallerThan10: "امکان برداشت کمتر از 10AED وجود ندارد!",
    pleaseVerifyFirst: "لطفا ابتدا اطلاعات حساب  بانکی خود را تکمیل کنید!",
    withdrawRequest: "درخواست واریز",
    myWalletBalance: "موجودی قابل برداشت",
    boxActiveStatus: "وضعیت فعال بودن باکس اطلاعات",
    inactive: "غیرفعال",
    withBordered: "خط‌دار",
    withoutBordered: "بدون خط",
    discount_coins: "تعداد کوین دریافتی",
    userPlanInfo: "اطلاعات پلن کاربر",
    givePlanToUser: "اعطای پلن و ویترین به کاربر",
    choosePlan: "انتخاب پلن",
    plan: "پلن",
    visitsChart: "نمودار بازدید‌ها",
    liveUsers: "کاربران آنلاین",
    newRegistrations: "ثبت‌نام‌های جدید",
    todayAllUnRegisteredUsers: "بازدید‌های امروز",
    daily_discount_notif: "یکبار ارسال پوش نوتیفیکیشن در روز در صورت وجود تخفیف جدید (لینک به کل تخفیف‌ها)",
    each_discount_notif: "ارسال پوش نوتیفیکیشن به ازای ایجاد هر تخفیف جدید (لینک به صفحه تخفیف)",
    newAds: "ویترین‌های امروز",
    newDiscounts: "تخفیف‌های امروز",
    sellerGuidLine: "دسترسی آسان",
    submitCompanyInfo: "تکمیل پروسه ثبت‌نام",
    letsGo: "مشاهده",
    enjoyPremiumFeatures: "از ویژگی های پریمیوم لذت ببرید",
    becomePremium: "پریمیوم شوید",
    upgradeYourPlan: "پلن خود را ارتقا دهید",
    companyPage: "صفحه کسب و کار",
    createBusinessPageToSee: "صفحه کسب و کار خود را ایجاد کنید تا اطلاعات آن را  در این قسمت مشاهده نمایید",
    numberOfAds: "تعداد ویترین‌ها",
    upgradeYourPlanToCreateAds: "جهت ایجاد ویترین پلن خود را ارتقا دهید",
    numberOfDiscounts: "تعداد تخفیف‌ها",
    totalVouchersSold: "ووچر‌های فروخته شده",
    viewList: "مشاهده",
    removeBusiness: "حذف صفحه کسب و کار",
    deleteBusiness: "حذف صفحه کسب و کار با موفقیت انجام شد",
    deleteBusinessFailed: "حذف صفحه کسب و کار با مشکل مواجه شد",
    reserve_description: "توضیح مربوط به رزرو",
    ordersListManage: "لیست سفارشات",
    sendMessageInWhatsapp: "پیام در واتساپ",
    reserveDiscount: "رزرو تخفیف",
    reserve_whatsapp_link: "لینک رزرو در واتساپ",
    reserveRequest: "درخواست رزرو",
    reserveRequestText: "سلام، من قصد رزرو این تخفیف را دارم",
    viewReserveRequest: "مشاهده درخواست رزرو",
    reserve: "رزرو",
    steps_banner: "بنر صفحه عضویت کسب‌و‌کار",
    report: "گزارش تخلف",
    addReport: "ارسال گزارش ",
    youCantDeleteThisDepartment: "امکان حذف دپارتمان‌های سیستمی وجود ندارد ",
    yourTotalCoins: "تعداد Phil Coin های شما",
    multipleBusinessAddingFailed: "امکان ایجاد بیش‌ از یک کسب‌و‌کار برای هر کاربر وجود ندارد",
    ifYouWantToSeeMoreInfoClick: "در صورتی که می‌خواهید اطلاعات این صفحه را به صورت کامل ببینید",
    clickHere: "کلیک کنید",
    attentionText: "توجه داشته باشید در صورت کلیک وارد بخش فارسی پنل می‌شوید",
    pleaseCompleteYourInfo: "لطفا اطلاعات ثبت‌نام خود را تکمیل کنید",
    youAreViewingUserRegisterInfo: "ادمین عزیز شما درحال مشاهده اطلاعات ثبت‌نام کاربر می‌باشید",
    toViewContentsInOtherLanguageToggleLanguage: "برای مشاهده موارد به زبان انگلیسی زبان را تغییر دهید",
    equal: "انگلیسی",
    logo: "لوگو",
    total_price: "مبلغ کل",
    factor_type: "نوع سفارش",
    buyDiscount: "خرید تخفیف",
    buyPlan: "خرید پلن",
    buyAdvertisement: "خرید ویترین",
    registrationCompleted: "تایید ثبت نام",
    removeFilter: "حذف فیلتر",
    myBusinessPage: "صفحه کسب‌و‌کار من",
    editDetailss: "ویرایش اطلاعات",
    registerInfoDone: "از این بخش می‌توانید اطلاعات ثبت نام کاربر را ویرایش نمایید",
    youCanEditUserRegisterInfo: "برای مشاهده و ویرایش اطلاعات ثبت نام کاربر از این دکمه استفاده نمایید ",
    deletePlanParentFailed: "برای حذف گروه پلن ابتدا پلن‌های این گروه را حذف کنید ",
    deletePlanFailed: "یک یا چند کاربر درحال استفاده از این پلن هستند  ",
    usedDate: "تاریخ استفاده وچر",
    inventoryName: "موجودی",
    monthlySales: "تعداد این ماه",
    totalSales: "تعداد کل",
    totalSale: "تعداد کل",
    plansSales: "تعداد سفارش پلن",
    adsSales: "تعداد سفارش ویترین",
    discountsSales: "تعداد سفارش تخفیف",
    purchaseAd: "خرید ویترین",
    ad_count_admin: "تعداد ویترین",
    verifiedUsers: "کاربران وریفای شده",
    verifiedUser: "کاربر وریفای شده",
    normalUser: "کاربر عادی",
    completingForm: "درحال تکمیل",
    rejected: "رد شده",
    userType: "نوع کاربر",
    removeSelect: "حذف انتخاب",
    userSuspensionStatus: "تعلیق کاربر",
    userSuspensionStatusDescription: "از این قسمت می‌توانید وضعیت ثبت‌نام و پلن کاربر را به حالت تعلیق شده درآورید",
    suspended: "تعلیق شده",
    yourAccountIsSuspended: "حساب کاربری شما تعلیق شده است",
    changeCover: "تغییر پیش‌نمایش",
    removeVideo: "حذف ویدیو",
    invalidEmail: "ایمیل وارد شده اشتباه است",
    recoverByEmail: "بازیابی رمزعبور با ایمیل",
    recoverByMobile: "بازیابی رمزعبور با موبایل",
    recoverPasswordTextEmail:"برای بازیابی رمزعبور ایمیل خود را وارد کنید",
    sendTokenTextEmail: "رمز یکبارمصرف به ایمیل شما ارسال شد",
    sendTokenTextEmail3: "زمان باقی مانده تا ارسال مجدد ایمیل",
    changeToEmail: "تغییر به ایمیل",
    changeToMobile: "تغییر به موبایل",
    confirm_payment: "تایید پرداخت",
    fileSize: "سایز",
    youCantAddToCart : "امکان افزودن تعداد بیشتر به سبد خرید وجود ندارد",
    farsiDescription : "توضیحات فارسی",
    englishDescription : "توضیحات انگلیسی",
    has_banner : "قابلیت قراردادن بنر در  صفحه کسب و کار",
    has_contact : "قابلیت نمایش اطلاعات تماس، آدرس و لوکیشن در  صفحه کسب و کار",
    has_video : "قابلیت آپلود ویدیو",
    has_business_about : "قابلیت نوشتن متن درباره کسب و کار",
    has_business_related_articles : "قابلیت نمایش مقالات مرتبط با کسب وکار",
    business_thumbnail_desc : "نمای اصلی کسب و کار شما در لیست کسب و کار ها" ,
    tax_notic : "این مبلغ شامل 5 درصد مالیات می باشد" ,
    doFilter : "اعمال فیلتر" ,
    searchUser : "جستجو کاربر" ,
    userCurrentStep : "مرحله فعلی کاربر" ,
    pleaseChooseARadio : "لطفا یک مورد را انتخاب کنید" ,
    businessType : "نوع کسب و کار" ,
    personBusiness : "شخصیت حقیقی" ,
    companyBusiness : "شخصیت حقوقی" ,
    no_license : "لایسنس معتبر ندارم" ,
    user_no_license : "لایسنس معتبر ندارد" ,
    download : "دانلود" ,
    downloadContract : "دانلود قرارداد" ,
    no_license_desc_1 : "برای ثبت نام در فیل لطفا قرارداد را دانلود و پس از امضا در قسمت بالا بارگذاری کنید" ,
    no_license_desc_2 : "در هر زمان شما می توانید مجدد به این منو باز گردید" ,
    addFile : "افزودن فایل" ,
    removeFile : "حذف فایل" ,
    uploaded : "آپلود شد" ,
    reserveSituation : "نحوه فعال‌سازی پلن جدید" ,
    do_reserve : "پلن تا اتمام پلن فعلی رزرو بماند" ,
    do_renew : "پلن هم‌اکنون جایگزین پلن فعلی شود" ,
    invite_code : "کد دعوت" ,
    invite_code_copied : "کد دعوت کپی شد" ,
    invite_text : "دعوت به فیل" ,
    invite_text_1 : "دوست من، شما از طرف" ,
    invite_text_2 : "عزیز به فیل دعوت شده اید." ,
    invite_text_3 : "فرآیند ثبت نام کسب وکار تان را تکمیل و کد دعوت را در قسمت کد معرف وارد نمائید." ,
    invitationText : "چند نفر را می‌شناسید که صاحب کسب‌وکار هستند؟ به فیل معرفی کنید و هدیه بگیرید" ,
    sendInvitation : "ارسال دعوت‌نامه" ,
    referral_code : "کد معرف" ,
    referral_code_desc : "کد معرف خود را وارد کنید" ,
    seoDetails : "مدیریت سئو" ,
    makePageNoIndex : "عدم نمایش به موتور‌های جستجو (No Index)" ,
    ad_meta_title : "متا تایتل ویترین‌ها" ,
    business_meta_title : "متا تایتل کسب‌و‌کار‌ها" ,
    discount_meta_title : "متا تایتل تخفیف‌ها" ,
    meta_title : "متا تایتل" ,
    makeAdPageNoIndex : "عدم نمایش دسته ویترین‌ها به موتور‌های جستجو (No Index)" ,
    makeDiscountPageNoIndex : "عدم نمایش دسته تخفیف‌ها به موتور‌های جستجو (No Index)" ,
    makeBusinessPageNoIndex : "عدم نمایش دسته کسب‌و‌کار‌ها به موتور‌های جستجو (No Index)" ,
    discount_description: "توضیح اجمالی تخفیف‌ها",
    business_description: "توضیح اجمالی کسب‌وکار‌ها",
    ad_description: "توضیح اجمالی ویترین‌ها",
    manageCTAs: "مدیریت فراخوانی‌ها",
    addCTA: "افزودن فراخوانی",
    ctaTitle: "تیتر اصلی",
    subtitle: "متن پایین",
    editCTA: "ویرایش فراخوان",
    makeCTA: "ایجاد فراخوان",
    btn_color: "رنگ دکمه",
    txt_color: "رنگ تیتر",
    btn_number: "دکمه شماره",
    btn_name: "نام دکمه",
    btn_link: "لینک دکمه",
    btn_icon: "آیکون دکمه",
    isNewsLetter: "فعال سازی حالت خبرنامه",
};