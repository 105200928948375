import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {changeURL, getTimeZone, scrollTop, truncate} from "../../../../config/values";
import Grid from "@material-ui/core/Grid/Grid";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import queryString from "query-string";
import SubTitle from "../../../components/elements/SubTitle";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import {makeSlug, translate} from "../../../../config/lang";
import MakeEqualDiscount from "../MakeEqualDiscount";
class Discounts extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                sorting:false,
                hidden: this.props.width === "xs",
                title: translate('thumbnail'),
                render: rowData =>
                    <a href={makeSlug(`discount/${rowData.slug}`,rowData.lang)} target="_blank">
                        <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
                    </a>
            },
            {
                title: translate('discount'),
                field: 'name',
                sorting:false,
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <Button
                        size="small"
                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                        component="a"
                        href={makeSlug(`discount/${rowData.slug}`,rowData.lang)}
                        target="_blank"
                    >
                        {truncate(rowData.name,17)}
                    </Button>
            },
            {
                title: translate('equalItem'),
                field: 'name',
                sorting:false,
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <>
                        {
                            !this.state.loading ?
                            <MakeEqualDiscount
                                list
                                equal_id={!!rowData.equal_id ? rowData.equal_id : null}
                                item={rowData.item}
                                reload={() => this.loadData()}
                            /> :
                                <span></span>
                        }
                    </>

            },
            { title: translate('creator'),
                field: 'owner',
                filterPlaceholder: translate('search'),
                sorting:false,
                hidden: !this.props.manage || this.props.width === "xs",
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {rowData.owner}
                        <br/>
                        <small style={{whiteSpace:"nowrap"}}><strong>{rowData.business_name}</strong></small>
                    </Typography>
            },
            { title: translate('confirmationStatusFilter'),
                sorting:false,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: translate('choose'),
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , 0:
                        <Typography color="textSecondary">{translate('unConfirmed')}</Typography>
                },
            },
            { title: translate('inventoryName'),
                field: 'inventory',
                filterPlaceholder: translate('search'),
                sorting:false,
                hidden: this.props.width === "xs",
            },
            {
                title: translate('expirationDate'),
                editable: 'never',
                sorting:false,
                hidden: this.props.width === "xs",
                field: 'expired_at',
                render: rowData =>
                    <>
                        {
                            !rowData.expired ?
                                <Typography className={this.classes.dateTable}>
                                    {`${moment.tz(rowData.expired_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}`}
                                </Typography> :
                                <Typography style={{color: "#ed1d1d"}}>{translate('expired')}</Typography>
                        }
                    </>
            },
            {
                title: translate('updateTime'),
                editable: 'never',
                sorting:false,
                hidden: this.props.width === "xs",
                field: 'created_at',
                render: rowData =>
                    <>
                        {
                            !!rowData.real_updated_at &&
                            <Typography className={this.classes.dateTable}>
                                {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).fromNow()}`}
                                <br/>
                                {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                            </Typography>
                        }
                    </>
            },
            { title: translate('activity'),
                editable: 'never',
                sorting:false,
                field: 'activity',
                render: rowData =>
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/edit/discount/${rowData.id}`}
                            endIcon={<ChevronRightRoundedIcon/>}
                            size={"small"}
                            style={{margin:5}}
                        >
                            {translate('edit')}
                        </Button>
                    </>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        status:"all",
        open_filter:false,
        page:0,
        def_page:0,
        per_page:TablePage,
        def_per_page:TablePage,
    };
    async componentDidMount(){
        await this.loadDefaults();
        scrollTop()

    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange();
        this.setState({
            loading:true
        })
    }
    async loadDefaults(){
        const values = queryString.parse(window.location.search);
        let status = values.status;
        let page = values.page;
        let per_page = values.per_page;

        if(!!status){
            await this.setState({
                status,
                open_filter:true,
            })
        }
        if(!!page){
            await this.setState({
                def_page:page,
            })
        }
        if(!!per_page){
            await this.setState({
                per_page:per_page,
                def_per_page:per_page,
            })
        }
        await this.setState({
            loading:false,
        })
    }
    async handleChangeStatus(e) {
        let target = e.target;
        let status = target.value;
        await this.setState({
            status
        });
        changeURL({status: target.value});
        this.loadData();
    }
    async handleChangePage(page){
        await changeURL({page: page})
        await this.setState({
            page,
        });
    }
    async handleChangeRowsPerPage(per_page){
        await changeURL({per_page: per_page})
        await this.setState({
            per_page,
        });
    }

    render(){
        let {status,open_filter,def_page,per_page,def_per_page} = this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRoundedIcon />}
                            >
                                <SubTitle align="left" style={{marginBottom:0}}>
                                    <IconButton size="small">
                                        <FilterListRoundedIcon/>
                                    </IconButton>
                                    {translate('filters')}
                                </SubTitle>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} alignItems="center" direction="row">
                                    <Grid item xs={12} md={4} lg={3}>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            label={translate('confirmationStatusFilter')}
                                            variant="outlined"
                                            value={status}
                                            onChange={this.handleChangeStatus.bind(this)}
                                        >
                                            <MenuItem value={"confirmed"}>{translate('confirmed')}</MenuItem>
                                            <MenuItem value={"unconfirmed"}>{translate('unConfirmed')}</MenuItem>
                                            <MenuItem value='all'>{translate('all')}</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableRef={this.tableRef}
                            icons={tableIcons}
                            title={
                                <Link to='/create/discount'>
                                    {
                                        this.props.width !== "xs" ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddBox />}
                                            >
                                                {translate('makeDiscount')}
                                            </Button> :
                                            <IconButton color="primary">
                                                <AddBox />
                                            </IconButton>
                                    }
                                </Link>
                            }
                            onChangePage={(page) => this.handleChangePage(page)}
                            onChangeRowsPerPage={(page) => this.handleChangeRowsPerPage(page)}
                            localization={getDataTableLocalization()}
                            columns={this.state.columns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    axios.get(`${defaultVariabls.url}/${this.props.manage ? "discounts" : "my-discounts"}/quickView?page=${parseInt(def_page > 0 ? def_page: query.page) + 1}&per_page=${def_per_page > TablePage ? def_per_page : query.pageSize}&search=${query.search}&status=${status}&lang=${this.props.locale}`, config)
                                        .then(async response => {
                                            await this.setState({
                                                loading:true
                                            })
                                            let data = [];
                                            // eslint-disable-next-line
                                            response.data.data.map((row) => {
                                                data.push({
                                                    thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                    name: row.name,
                                                    id: row.id,
                                                    owner: row.owner.first_name + " " + row.owner.last_name,
                                                    business_name: !!row.business ? row.business.name : row.owner.business_name,
                                                    inventory: row.inventory,
                                                    confirmed: row.confirmed,
                                                    expired_at: row.expired_at,
                                                    updated_at: row.updated_at,
                                                    slug: row.slug,
                                                    lang: row.lang,
                                                    equal_id: row.equal_id,
                                                    item: row,
                                                    expired: row.expired,
                                                    real_updated_at:row.real_updated_at
                                                })
                                            });
                                            if(def_page > 0){
                                                this.setState({
                                                    def_page:0,
                                                })
                                            }
                                            resolve({
                                                data: data,
                                                page: response.data.current_page - 1,
                                                totalCount: response.data.total,
                                            })
                                            await setTimeout(() => {
                                                this.setState({
                                                    loading:false
                                                })
                                            }, 1000);
                                        }).catch(error => {
                                        console.log(error.response)
                                    })
                                })
                            }
                            options={{
                                draggable: false,
                                pageSize: per_page,
                                pageSizeOptions: TableCount,
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Discounts)))));