import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import AllInboxRoundedIcon from '@material-ui/icons/AllInboxRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, translate} from "../../../../config/lang";
import MakeEqualCTA from "./MakeEqualCTA";
import ColorPicker from "../../../components/fields/ColorPicker";
import SubTitle from "../../../components/elements/SubTitle";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class MakeCTA extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            title:"",
            subtitle:"",
            btn_name_1:"",
            btn_link_1:"",
            btn_icon_1:"",
            btn_name_2:"",
            btn_link_2:"",
            btn_icon_2:"",
            btn_name_3:"",
            btn_link_3:"",
            btn_icon_3:"",
            btn_name_4:"",
            btn_link_4:"",
            btn_icon_4:"",
            btn_name_5:"",
            btn_link_5:"",
            btn_icon_5:"",
            btn_name_6:"",
            btn_link_6:"",
            btn_icon_6:"",
        },
        errors:{},
        loading: true,
        SubmitLoading: false,
        id:this.props.match.params.id,
        editMode:false,
        is_newsletter:false,
        updated_at:null,
        created_at:null,
        slug: null,
        equal: null,
        item: null,
        bg_color:this.props.primary_color,
        btn_color:this.props.secondary_color,
        txt_color:"#fff",

    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePublish(){
        this.setState({
            publish: !this.state.publish,
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/cta/getById/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            title: response.data.title,
                            subtitle: !!response.data.subtitle ? response.data.subtitle : '',
                            btn_name_1: !!response.data.btn_name_1 ? response.data.btn_name_1 : '',
                            btn_link_1: !!response.data.btn_link_1 ? response.data.btn_link_1 : '',
                            btn_icon_1: !!response.data.btn_icon_1 ? response.data.btn_icon_1 : '',
                            btn_name_2: !!response.data.btn_name_2 ? response.data.btn_name_2 : '',
                            btn_link_2: !!response.data.btn_link_2 ? response.data.btn_link_2 : '',
                            btn_icon_2: !!response.data.btn_icon_2 ? response.data.btn_icon_2 : '',
                            btn_name_3: !!response.data.btn_name_3 ? response.data.btn_name_3 : '',
                            btn_link_3: !!response.data.btn_link_3 ? response.data.btn_link_3 : '',
                            btn_icon_3: !!response.data.btn_icon_3 ? response.data.btn_icon_3 : '',
                            btn_name_4: !!response.data.btn_name_4 ? response.data.btn_name_4 : '',
                            btn_link_4: !!response.data.btn_link_4 ? response.data.btn_link_4 : '',
                            btn_icon_4: !!response.data.btn_icon_4 ? response.data.btn_icon_4 : '',
                            btn_name_5: !!response.data.btn_name_5 ? response.data.btn_name_5 : '',
                            btn_link_5: !!response.data.btn_link_5 ? response.data.btn_link_5 : '',
                            btn_icon_5: !!response.data.btn_icon_5 ? response.data.btn_icon_5 : '',
                            btn_name_6: !!response.data.btn_name_6 ? response.data.btn_name_6 : '',
                            btn_link_6: !!response.data.btn_link_6 ? response.data.btn_link_6 : '',
                            btn_icon_6: !!response.data.btn_icon_6 ? response.data.btn_icon_6 : '',
                        },
                        updated_at:response.data.updated_at,
                        bg_color:response.data.bg_color,
                        txt_color:response.data.txt_color,
                        btn_color:response.data.btn_color,
                        created_at:response.data.created_at,
                        is_newsletter:!!response.data.is_newsletter,
                        slug: response.data.slug,
                        equal: response.data.equal,
                        item: response.data,
                    })
                    this.setState({
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)){
            formIsValid = false;
            errors['title'] = translate('emptyField');
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    handleRequest(){
        let { fields,id,editMode,bg_color,txt_color,btn_color,is_newsletter} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('subtitle', fields.subtitle);
        data.append('lang', this.props.locale);
        data.append('bg_color', bg_color);
        data.append('btn_color', btn_color);
        data.append('txt_color', txt_color);
        data.append('btn_name_1', fields.btn_name_1);
        data.append('btn_icon_1', fields.btn_icon_1);
        data.append('btn_link_1', fields.btn_link_1);
        data.append('btn_name_2', fields.btn_name_2);
        data.append('btn_icon_2', fields.btn_icon_2);
        data.append('btn_link_2', fields.btn_link_2);
        data.append('btn_name_3', fields.btn_name_3);
        data.append('btn_icon_3', fields.btn_icon_3);
        data.append('btn_link_3', fields.btn_link_3);
        data.append('btn_name_4', fields.btn_name_4);
        data.append('btn_icon_4', fields.btn_icon_4);
        data.append('btn_link_4', fields.btn_link_4);
        data.append('btn_name_5', fields.btn_name_5);
        data.append('btn_icon_5', fields.btn_icon_5);
        data.append('btn_link_5', fields.btn_link_5);
        data.append('btn_name_6', fields.btn_name_6);
        data.append('btn_icon_6', fields.btn_icon_6);
        data.append('btn_link_6', fields.btn_link_6);
        data.append('is_newsletter', !!is_newsletter ? 1 : 0);
        data.append('id', id ? id : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/cta/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('contentEdited') : translate('contentAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/ctas`);

                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('contentEditingFailed') : translate('contentAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {fields, errors,SubmitLoading,editMode,equal,item,btn_color,bg_color,txt_color,loading} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editCTA') : translate('makeCTA')}>
            {
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11} >
                        <Grid container spacing={2}  justifyContent="center">
                            {
                                !!editMode &&
                                <>
                                    {
                                        loading ? null :
                                            <Grid item xs={12}>
                                                <AppCard
                                                    className={this.classes.equalAppCard}
                                                >
                                                    <div
                                                        className={this.classes.equalHolder}
                                                    >
                                                        <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                        <MakeEqualCTA
                                                            equal_id={!!equal ? equal.id : null}
                                                            item={item}
                                                            reload={() => this.loadData()}
                                                        />
                                                    </div>
                                                </AppCard>
                                            </Grid>
                                    }
                                </>
                            }
                            <Grid item xs={12} md={8} lg={9}>

                                <AppCard
                                    title={editMode ? translate('editCTA') : translate('makeCTA')}
                                    action={
                                        <IconButton><AllInboxRoundedIcon color="primary"/></IconButton>
                                    }>
                                    {
                                        this.state.loading ? <AppCardLoaderFields/> :
                                            <Grid container spacing={2} >
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.is_newsletter} onChange={() => this.setState({is_newsletter:!this.state.is_newsletter})} />}
                                                            label={translate('isNewsLetter')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="title"
                                                        label={translate('ctaTitle')}
                                                        type="text"
                                                        size="small"
                                                        value={fields.title}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['title']}
                                                        helperText={errors['title']}
                                                    />
                                                </Grid>
                                                {
                                                    !this.state.is_newsletter &&
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                variant="outlined"
                                                                name="subtitle"
                                                                label={translate('subtitle')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.subtitle}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['subtitle']}
                                                                helperText={errors['subtitle']}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 1</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_1"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_1}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_1']}
                                                                helperText={errors['btn_name_1']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_1"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_1}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_1']}
                                                                helperText={errors['btn_link_1']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_1"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_1}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_1']}
                                                                helperText={errors['btn_icon_1']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 2</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_2"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_2}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_2']}
                                                                helperText={errors['btn_name_2']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_2"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_2}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_2']}
                                                                helperText={errors['btn_link_2']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_2"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_2}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_2']}
                                                                helperText={errors['btn_icon_2']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 3</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_3"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_3}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_3']}
                                                                helperText={errors['btn_name_3']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_3"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_3}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_3']}
                                                                helperText={errors['btn_link_3']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_3"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_3}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_3']}
                                                                helperText={errors['btn_icon_3']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 4</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_4"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_4}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_4']}
                                                                helperText={errors['btn_name_4']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_4"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_4}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_4']}
                                                                helperText={errors['btn_link_4']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_4"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_4}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_4']}
                                                                helperText={errors['btn_icon_4']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 5</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_5"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_5}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_5']}
                                                                helperText={errors['btn_name_5']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_5"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_5}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_5']}
                                                                helperText={errors['btn_link_5']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_5"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_5}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_5']}
                                                                helperText={errors['btn_icon_5']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} style={{marginBottom:0,paddingBottom:0}}>
                                                            <SubTitleLight style={{marginBottom:0}} align="left">{translate('btn_number')} 6</SubTitleLight>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_name_6"
                                                                label={translate('btn_name')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_name_6}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_name_6']}
                                                                helperText={errors['btn_name_6']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField

                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_link_6"
                                                                label={translate('btn_link')}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_link_6}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_link_6']}
                                                                helperText={errors['btn_link_6']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                name="btn_icon_6"
                                                                label={translate('btn_icon')}
                                                                placeholder={"fas fa-chevron-left"}
                                                                type="text"
                                                                size="small"
                                                                value={fields.btn_icon_6}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['btn_icon_6']}
                                                                helperText={errors['btn_icon_6']}
                                                            />
                                                            <Button
                                                                color="primary"
                                                                size="small"
                                                                component="a"
                                                                href="https://fontawesome.com/v5/icons"
                                                            >
                                                                {translate('iconsList')}
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                }

                                                <Hidden smDown>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? translate('editCTA') : translate('makeCTA')}
                                                    </LoadingButton>
                                                </Grid>
                                                </Hidden>
                                            </Grid>
                                    }
                                </AppCard>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Hidden smDown>
                                            <Grid item xs={12} style={{paddingTop: 0}}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{width: "100%"}}
                                                    onClick={this.handleSubmit.bind(this)}
                                                    loading={SubmitLoading}
                                                >
                                                    {editMode ? translate('editCTA'): translate('makeCTA')}
                                                </LoadingButton>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    {
                                        !loading &&
                                        <>
                                            <Grid item xs={12}>
                                                <ColorPicker
                                                    color={bg_color}
                                                    title={translate('bg_color')}
                                                    onChange={(bg_color) => this.setState({bg_color})}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ColorPicker
                                                    color={btn_color}
                                                    title={translate('btn_color')}
                                                    onChange={(btn_color) => this.setState({btn_color})}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ColorPicker
                                                    color={txt_color}
                                                    title={translate('txt_color')}
                                                    onChange={(txt_color) => this.setState({txt_color})}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    primary_color: state.primary_color,
    secondary_color: state.secondary_color,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeCTA))));
