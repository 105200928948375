export const EN = {
    fa: "FA",
    en: "EN",
    login:"Login",
    existEmail: "This email is already registered.",
    existMobile: "This mobile number is already registered.",
    emptyEmail: "Enter your email address.",
    emptyMobile: "Enter your mobile number.",
    wrongEmail: "Email format is wrong.",
    wrongMobile: "The mobile number format is wrong.",
    emptyEmailOrMobile:"Enter your email address.",
    loginError: "Your Email  is wrong.",
    emailOrMobile: "Email or Phone number",
    first_name: "First Name",
    last_name: "Last Name",
    phone: "call number",
    passwordConfirm: "repeat password",
    passwordStrength: "at least 6 characters, including numbers and letters",
    weakPassword: "The password must be at least 6 characters long, including numbers and letters",
    passwordChanged:"Password changed successfully.",
    currentPasswordError: "The current password is not correct.",
    currentPassword:"current password",
    register: "Register",
    doRegister: "Create new account",
    dontHaveAccount: "Don't have an account?",
    haveAccount: "Do you have an account?",
    forgetPass: "Forgot Password",
    loginWithToken:"Login with mobile number",
    password: "Password",
    backToLogin: "Back to login page",
    doLogin: "Log in!",
    rules: "terms and conditions",
    withRegistering: "By registering on this site, I accept its",
    privacyPolicy: "Privacy Policy",
    privacyPolicyConfirmation: ".",
    mobile: "Mobile",
    mobileSample: "like 09123456789",
    email: "email",
    sms: "short message",
    verifyEmail: "Verify Email",
    registerSuccess: "User registered successfully.",
    registerError: "Error creating user",
    youLoggedOff:"You are logged out",
    mobileVerified:"Your mobile number has been verified.",
    wrongToken:"The verification code is wrong. ",
    mobileVerify: "Mobile Verification",
    verifyMobile: "verify mobile number",
    sendTokenText1:"Your one-time token sent to",
    sendTokenText2:" .",
    sendTokenTextResend:"The one-time password has been resent to you.",
    sendTokenText3:"Remaining time to resend SMS",
    logOut: "Logout",
    confirm: "Confirm",
    token:"verification code",
    forgetPassword: "Forgot Password",
    invalidMobileNumber: "This Mobile number does not exist.",
    recoverPassword:"recover password",
    recoverPasswordText:"Enter your mobile number to recover your password.",
    sendAgain: "Send again",
    changePassword: "Change password",
    managePassword: "Manage password",
    newPassword:"New password",
    newPasswordConfirm:"Repeat new password",
    loginWithUserPass:"Login with Email",
    addYourNumberFirst:"Enter your mobile number to enter the site.",
    errorHappens:"An error occurred while loading the Website!",
    allChangesSaved:"All changes saved.",
    allChangesSavingFailed:"Saving changes failed!",
    connectionError:'Your internet is down.',
    pleaseCheckTheBox:"Confirm Terms and Conditions Please!",
    emptyField:'The field is empty!',
    makingTokenError: "There is a problem creating the verification code.",
    editMobile: "Edit mobile number",
    editMobileDesc: "If you want to change your mobile number (",
    editMobileDesc2: "), enter it.",
    save: "Save",
    dataAdded: "Your data has been created.",
    dataAddingFailed: "There was a problem creating data.",
    emptyPassword: "Enter your password",
    unreadNotifications: "Unread notifications",
    readNotifications: "Read Notifications",
    noNotifications: "There is no Notification!",
    nothingFound: "The page does not exist!",
    markAsRead: 'Mark as Read',
    copyright: "All rights reserved © ",
    dashboard:"Dashboard",
    account: "Manage Account",
    fileManager: "File manager",
    myDetails: "My Account",
    myAccount: "My Account",
    doYouWanaExit: "Do you want to exit?",
    readMore: "Read more",
    areYouSure: "Are you sure?",
    yes: "Yes",
    no: "No",
    userPanel: "Panel",
    userMobileIsConfirmed: "The user's mobile number is confirmed.",
    userMobileIsNotConfirmed: "The user's mobile number is not confirmed.",
    doYouWanaConfirm: "Do you confirm the user's mobile number?",
    loginToUserAccount: "login to this user's account",
    userShouldConfirmMobileFirst: "The user's mobile number must be confirmed first!",
    youAreInSomeOneAccount: "You are in someone else's account!",
    backToMyAccount: "back",
    setDetails: "Save Changes",
    detailsSet:"Information was registered.",
    detailsSetFailed:"There was a problem registering information.",
    detailsGetFailed:"There was a problem getting information.",
    someFieldsHasErrorPleaseCheckThem: "One or more fields have errors, please check them",
    someFieldsHasError: "One or more fields have errors, please check",
    maxLength70: "The length of this field should not exceed 70 characters.",
    maxLength150: "The length of this field should not exceed 150 characters.",
    maxLength255: "The length of this field should not exceed 255 characters.",
    maxLength450: "The length of this field should not exceed 450 characters.",
    maxLength500: "The length of this field should not exceed 500 characters.",
    maxLength1000: "The length of this field should not exceed 1000 characters.",
    maxLength6000: "The length of this field should not exceed 6000 characters.",
    chooseMedia: "Choose File",
    submitErrorFile:"There is a problem with the submitted file.",
    imageSizeWidth700: "The proper width of the image is 700px.",
    thumbnailSize: "Minimum image width: 600px",
    userAvatarSet:"Your profile picture has been updated.",
    profileImage: "Profile Image",
    userAvatarSetFailed:"There was a problem in changing the profile picture.",
    loadingThumbnailFailed: "There was a problem loading the image",
    ChooseImageLink:" To copy the link, choose the desired size.",
    deleteFile:"File deleted successfully",
    deleteFileFailed: "There was a problem deleting the file.",
    deleteSelectedFiles:"Files deleted successfully",
    alt: "alternative text (alt)",
    chooseImage: "Choose image",
    emptyImage: "Choose an image!",
    descriptionPlaceHolder: "Write the relevant description",
    words:"Word",
    characters:"Character",
    uploadImage: "Upload Media",
    ThumbnailTitle: "Main Image",
    loading: "Loading...",
    makePassword: "Make password",
    addImage: "Add image",
    imageName: "File name",
    ownerMedia:"Publisher",
    insertMedia:"Insert file",
    insert: "Insert",
    linkCopied:"The link was copied.",
    uploadSuccess: "Upload was successful",
    editFile:"File edited successfully",
    editFileFailed: "Editing the file encountered a problem.",
    tableAdded:"Table was created",
    tableAddingFailed:"Adding table failed.",
    deleteTable:"Table deleted successfully",
    deleteTableFailed:"There was a problem deleting the table.",
    tableEdited:"The table was edited.",
    tableEditingFailed:"Table editing failed.",
    selfFiles: "My files",
    adminsFiles: "Site Admins files",
    everyBodyFiles: "All users files",
    uploading: "Uploading...",
    uploadDone: "File uploaded.",
    allTypes: "All formats",
    image: "Image",
    video: "Video",
    document: "Document",
    chooseVideo:"Choose video",
    multiImport:"select media",
    filesTable: "saved files",
    lastUpdate: "Last Update",
    search: "Search",
    from: "from",
    labelRowsSelect: "Rows",
    labelRowsPerPage: "Number of rows per page",
    firstAriaLabel: "First page",
    firstTooltip: "First page",
    previousAriaLabel: "Previous page",
    previousTooltip: "Previous page",
    nextAriaLabel: "Next page",
    nextTooltip: "Next page",
    lastAriaLabel: "Last page",
    lastTooltip: "Last page",
    exportTitle: "Get output file",
    exportAriaLabel: "Get output file",
    exportCSVName: "Get CSV output",
    emptyDataSourceMessage: 'There is no content to display!',
    addTooltip: 'Add',
    deleteTooltip: 'Delete',
    editTooltip: 'Quick Edit',
    filterTooltip: 'Filter',
    deleteText: 'Are you sure to delete this row?',
    cancelTooltip: 'Cancel',
    saveTooltip: 'Save',
    rowsSelected: 'selected row',
    uploadedDate: "upload time",
    linkPlaceholder:"Enter a URL",
    number: "Number",
    icon: "Icon",
    link: "Link",
    filters: "Filters",
    chooseColor: "Choose color",
    chooseFormat: "Choose format",
    editAdvertisement: "Edit Showcase",
    makeAdvertisement:"Create a Showcase",
    advertisementName:"Showcase Title",
    advertisements: "Advertisements",
    advertisement: "Advertisement",
    advertisementsList:"List of Showcases",
    viewAdvertisement:"View Showcase",
    updateTime: "Update",
    created_at: "Created date",
    updated_at: "Update date",
    gallery: 'Image gallery',
    requestToDeleteAd: "Request to delete Showcase",
    removeAd: "Remove Showcase",
    requestedToDelete: "The removal request has been sent!",
    pageNotFound: "This page is not available!",
    contentAdded: "Your post has been created.",
    contentAddingFailed: "There was a problem creating the post.",
    contentEdited: "Your post has been edited.",
    contentEditingFailed: "There was a problem editing the post.",
    deleteContent: "Post deleted.",
    deleteContentFailed: "There was a problem deleting the post.",
    firstConfirmShop: "Confirm this user's shop first!",
    confirmationStatusDescription: "Information confirmation status",
    confirmationStatusFilter: "Status",
    confirmStatus: "Status",
    userConfirmationStatus: "Confirmation status of user information",
    confirmed: "Confirmed",
    unConfirmed: "Waiting",
    all: "All",
    yourAdvertisementIsActive: "Congratulations! Your Showcase is verified and active.",
    yourAdvertisementIsInchecking: "Your Showcase is being checked!",
    price:"Price",
    changeCategories: "Change categories",
    advertisementDescription: "Complete Showcase explanation",
    seoDescription: "brief explanation",
    welcomeToPanel: "Welcome to Phil's user panel!",
    agreement: "Terms & Conditions",
    pleaseConfirmTerms: "Please read and accept the Term of use page.",
    rulesConfirmation: " and approve it.",
    with: "I agree with the ",
    manageAdvertisements: "Manage Showcases",
    myAdvertisements: "My Showcases",
    thumbnail: "Image",
    creator: "Publisher",
    edit: "Edit",
    view: "View",
    activity: 'Activity',
    details: 'Details',
    deleteAdvertisement: "Showcase deleted!",
    deleteAdvertisementFailed: "There was a problem deleting the Showcase!",
    chooseCategory: "Choose Category",
    maxGallerySizeIs9: "The maximum number of gallery images is 9!",
    manageComments: "Manage comments",
    manageCategories: "Manage Categories",
    usersManagement: "User management",
    allUsers: "All Users",
    usersActivity: "user activity",
    managePages: "Manage Pages",
    manageContact: "Contact Form",
    siteSettings: "Site settings",
    siteInformation: "Main Settings",
    manageSlideShows: "Home slideshow",
    manageAllSlideShows: "Manage slideshows",
    manageBanners: "Manage banners",
    manageHomeBanners: "Home banners slider",
    editSlideShow: "Edit slideshow",
    custom_css: "Additional CSS code",
    custom_js: "Additional Java Script Code",
    custom_head: "Added code in <head> Website",
    custom_body: "Additional code in the <body> of the Website",
    custom_code: "additional code",
    siteContactInformation: "contact information",
    moreInfo: "More information",
    makeCategory: "Create Category",
    editCategory: "Edit Category",
    categoryName: "category name",
    categoryParentName: "Parent Category",
    categories: "Categories",
    pleaseChooseCategoriesFirst: "Please choose a category first!",
    category: "Category",
    chooseSubCategory: "Choose subcategory",
    chooseMainCategory: "Choose the main category",
    removeImage: 'Remove image',
    viewCategory: 'View Category',
    username: "Username",
    userName: "Name and Surname",
    confirmationStatus: "Confirmation of information",
    registerDate: "Registration Date",
    changeRole: "Change access level",
    role: "Access level",
    allRoles: "All access levels",
    exportUsersList: "Download list of users",
    addUser: "Add User",
    editUser: "Edit User",
    editDetails: "Edit Profile",
    dataEdited: "Your information has been edited.",
    dataEditingFailed: "There was a problem editing data.",
    deleteData: "Data deleted.",
    deleteDataFailed: "There was a problem deleting this data.",
    admin: "Admin",
    user: "User",
    supportUser: "Support",
    product_manager: "Product Manager",
    support: "Support",
    adminType: "Admins",
    normalType: "Other users",
    usersLog: "User activities",
    saveCode: "Save Codes",
    writeYourCodehere: "Write your codes here",
    customCodeTitle: "Add the codes you need!",
    customCodeText: "In this section, you can add the codes you need in different sections of your website and customize them!",
    main_phone: "Website Phone number",
    main_email: "Website Email",
    main_mobile: "Website Mobile number",
    main_address: "Website Address",
    facebook: "Facebook link",
    linkedin: "LinkedIn Link",
    pinterest: "Pinterest link",
    instagram: "Instagram link",
    twitter: "Twitter Link",
    whatsapp: "Whatsapp Number",
    telegram: "Telegram link",
    headerAndFooter: "مدیریت هدر و فوتر",
    short_description: "short description of the website",
    footer_text: "Footer description text",
    footer_certificates: "Footer Symbols Code",
    map_script: "Contact page map code",
    bg_image: "Background image",
    gradientBg: "Gradient background",
    solidBg: "Solid color background",
    imageBg: "Background with image",
    gradientBgFirstColor: "Gradient first color",
    gradientBgSecondColor: "Gradient second color",
    bg_type: "Background image type",
    slideShowImages: "Image elements",
    SlideShowContent: "Slideshow content",
    nextStep: "Next part",
    mainImageSlide: "Main image slide",
    contactInformation: "Website Contact information",
    footerCertificationTitle: "Certificates Code",
    footerCertificationText: "In this section, you can put the codes received from different organizations such as trust symbol, digital media, etc.",
    mapScriptTitle: "Map Script",
    mapScriptText: "In this section, you can put the codes received from different map platforms, such as Google Map, to be displayed on the Contact page.",
    saveChanges: "Save Changes",
    adminDashboard: "Admin Dashboard",
    userDashboard: "User Dashboard",
    currencies: "Currencies",
    addContactInformation: "Add Contact Information",
    phoneNumber: "Phone number",
    addPhoneNumber: "Add number",
    addEmail: "Add Email",
    relatedLinks: "Related Links",
    websiteLink: "Website URL",
    facebookLink: "Facebook URL",
    whatsappLink: "WhatsApp number",
    telegramLink: "Telegram URL",
    instagramLink: "Instagram URL",
    twitterLink: "Twitter URL",
    websitePlaceHolder: "https://yourwebsite.com",
    facebookPlaceHolder: "https://facebook.com/your_page",
    whatsappPlaceHolder: "+9712345678",
    telegramPlaceHolder: "https://t.me/your_number",
    instagramPlaceHolder: "https://instagram.com/your_page",
    twitterPlaceHolder: "https://twitter.com/your_page",
    phoneIsWrong: "Phone number is wrong!",
    youtube: "Youtube",
    addFromYoutube: "Add video from Youtube",
    addYoutubeLinkHere: "Please insert a video URL from Youtube in the field below!",
    addVideo: "Add Video",
    youtubeLink: "Youtube video URL",
    back: "Back",
    wrongURlForYoutube: "URL is Wrong!",
    toggleDark: "Dark mode",
    toggleLight: "Light mode",
    addMainDetails: "Main details",
    addImages: "Add Images",
    primaryAdImage: "Ad Primary image",
    savedFiles: "Uploaded files",
    fileSize20MB: "File size must be less than 20MB!",
    fileSize10mb: "File size must be less than 10MB!",
    fileSize100mb: "File size must be less than 100MB!",
    addPreviewToVideo: "Add preview to video",
    doYouWantToAddThumbnail: "Do you want to add a thumbnail to this video?",
    addPreviewImage: "select preview image",
    addVideoWithoutPreview: "Add video without preview",
    adsGallery: "Ad Gallery",
    removeItem: "Remove Item",
    addItem: "Add Item",
    address: "Address",
    addressAndLocation: "Address and Location",
    country: "Country",
    state: "State",
    yourLocation: "Your location",
    searchInMap: "Search in Map",
    adAdded: "Your Showcase has been created.",
    adAddingFailed: "There was a problem creating the Showcase.",
    adEdited: "Your Showcase has been edited.",
    adEditingFailed: "There was a problem editing the Showcase.",
    commentEdited: "Comment edited",
    commentAdded:"Comment was created",
    commentAddingFailed:"There was a problem adding a comment.",
    commentEditingFailed: "There was a problem editing the comment.",
    commentRemoved: "Comment removed successfully",
    rating: "rating",
    comment: "Comment",
    comments: "Comments",
    editComment: "Edit Comment",
    addComment: "Add Comment",
    chooseAdForComment: "choose Showcase for comment",
    noAds: "No Showcases found!",
    commentForReplayDescription: "Choose here if you want to reply to the comment",
    parentCommentDesc : "You are replying to the following comment:",
    site_logo: "Site logo",
    favicon: "Site icon (FavIcon)",
    square_logo: "Square logo",
    page_header: "Header of internal pages",
    pagination_count: "Items per page",
    page_count: "Number of pages to load with Ajax",
    app_name: "Website name",
    youtubeChanel: "Youtube chanel link",
    youtubePlaceHolder: "https://www.youtube.com/channel/your_chanel",
    viewPreview: "Preview",
    userLikes: "Users like",
    adInfo: "Showcase information",
    viewAd: "View Showcase",
    pleaseChooseYourLocation: "Please choose your location!",
    fileInUse: "This file is in use!",
    backToHome: "Back",
    basic_plan: "Basic plan",
    bronze_plan: "Bronze Plan",
    silver_plan: "Silver Plan",
    gold_plan: "Gold Plan",
    planInfo: "Plan Info",
    yourPlan: "Your Plan",
    months: "Months",
    adValidityPeriod: "Showcase validity period",
    monthlyAdCount: "Number of Showcases that can be created",
    monthlyEditCount: "The number of edits that can be done",
    viewPricing: "View Pricing",
    youCantAddAd: "You can't add a new Showcase!",
    youCantEditAd: "You cannot edit the Showcase!",
    planLimitationDescription: "According to your plan, it is not possible to do this, please see the tariffs and prepare your suitable plan!",
    expired_at: "Until the Showcase expires",
    yourPlanDoesntSupportThis: "Your plan does not support this",
    yourPlanDoesntSupportThisDesc: "Upgrade your plan to use this feature",
    manageFields: "Manage fields",
    fieldName: "Persian Field Name",
    addField: "Add Field",
    makeField: "Create field",
    adminHasFullAccess: "The admin has full access!",
    pleaseAdCategoryFirst: "Please select a category first!",
    specificFields: "Specific fields",
    totalVisitsCount: "Total Visits",
    todayVisitsCount: "Today's visits",
    totalCommentsCount: "Comments",
    noLogFound: "No results found",
    allowed_comments: "Is it allowed to post comments?",
    allowed: "Allowed",
    not_allowed: "Not allowed",
    expired: "Expired",
    equalItem: "Persian Equal",
    addEqual: "Add equal",
    noEqual: "There is no equal item",
    emptyTableCells: "Table is empty",
    AddTable : "Create a Table",
    EditTable : "Edit Table",
    TableBuilder:"Table Builder",
    tableName: "Table name",
    makeTable: "Create Table",
    chooseTableStructure:"Choose Table Columns and Rows",
    columns:"Columns",
    rows:"Rows",
    addRows:"Add Row",
    addColumns:"Add Column",
    buildTable:"Build Table",
    withHeading:"Table With Heading",
    withoutHeading:"Tale Without Heading",
    tableTdPlaceholder:"Type your Content...",
    shortcode:"Short Code",
    insertTable:"Insert Table",
    manageCategoriesOrder: "Manage the order of categories",
    editSubMenuOrder:"Submenu Order",
    noCategoryFound: "Category not found!",
    youAreEditingParentCategoriesOrder:"You are editing parent categories!",
    youAreEditingCategoriesOrder:"Sorting subcategories of: ",
    youAreEditingParentCategoriesOrderDesc:"Order each category's subcategory by clicking the edit button.",
    update:"Update",
    updateAdReal:"Update Showcase",
    updateAdRealDesc: "You can update your Showcase to be in a higher position in the list of Showcases!",
    advertisementUpdated:"Showcase updated!",
    rate:"Rate",
    myBusiness: "My Business",
    businessAdded: "Your business has been created.",
    businessAddingFailed: "There was a problem adding business.",
    businessEdited: "Your business has been edited.",
    businessEditingFailed: "There was a problem editing the business.",
    editBusiness:"Edit Business",
    makeBusiness: "Create Business",
    businessName: "Business Name",
    businesses: "businesses",
    business: "Business",
    businessesList:"List of businesses",
    viewBusiness:"View Business",
    businessLogo: "Business Logo",
    yourBusinessIsActive: "Congratulations! Your business is verified and active.",
    yourBusinessIsInchecking: "Your business is being checked!",
    businessBanners: "Business banners",
    foundingYear: "Establishment Year",
    businessVideos: "Videos",
    manageBusinesses: "Manage Businesses",
    businessBio: "Business Brief Description",
    primaryBusinessImage: "Business Primary Image",
    youCantAddBusiness: "Unable to add business!",
    pageInfo: "Page Info",
    remove: "Remove",
    deleteIt: "Delete",
    active: "Active",
    activeStatus: "Status",
    addSlideShow: "Add Slideshow",
    chooseAdForSlide: "Choose Showcase for slide",
    banner_image: "Banner image",
    addBanner: "Add banner",
    editBanner: "Edit banner",
    pageTitle: "Page Title",
    addPage: "Add page",
    makePage: "Create page",
    editPage: "Edit page",
    viewPage: "View page",
    youCantDeletePermanentPages: "Can't delete permanent pages!",
    manageFAQ: "Frequently Asked Questions",
    addFAQ: "Add FAQ",
    editFAQ: "Edit FAQ",
    question: "Question",
    answer: "Answer",
    editDiscount: "Edit discount",
    makeDiscount: "Create a Discount",
    discountName: "Discount Title",
    discounts: "Discounts",
    discount: "Discount",
    discountsList: "List of discounts",
    viewDiscount: "View discount",
    manageDiscounts: "Manage Discounts",
    myDiscounts: "My Discounts",
    discountAdded: "Your discount has been created.",
    discountAddingFailed: "There was a problem creating the discount.",
    discountEdited: "Your discount has been edited.",
    discountEditingFailed: "There was a problem editing the discount.",
    deleteDiscount: "Discount deleted!",
    deleteDiscountFailed: "There was a problem deleting the discount!",
    discountInfo: "Discount Info",
    primaryDiscountImage: "Primary Discount Image",
    discountGallery: "Image Gallery",
    discountDescription: "Discount Information",
    requestToDeleteDiscount: "request to delete discount",
    removeDiscount: "Remove discount",
    choose: "Choose",
    discount_percent: "Discount percentage",
    priceDiscount: "Actual amount",
    discount_price: "Amount including discount",
    inventory: "Number of discount codes",
    discount_expire_time: "Discount expiration time",
    dateShouldBeAfterToday: "The time is wrong!",
    discount_expired_at: "Until the discount expires",
    manageDiscountsSlider: "Discounts Slider",
    bannerTitle: "Banner Title",
    wrongPercent: "Wrong percentage",
    discount_banner_1: "Single discount page banner 1",
    discount_banner_2: "Single discount page banner 2",
    bannerPosition: "Banner Position",
    sliderTitle: "Slide Title",
    primary_button_text:'Primary button text',
    primary_button_icon:'Primary button icon',
    primary_button_link:"Primary button link",
    manageAdsComments:"Comment on showcases",
    manageBusinessesComments:"Comment on businesses",
    manageDiscountsComments:"Comment on discounts",
    ad:"Showcase",
    chooseItemForComment:"Choose item for comment",
    noItem:"No item found!",
    term_of_use: "Terms of use",
    articles: "Phil Mag",
    manageContents: "Manage Phil Mag",
    contentsList: "List of Articles",
    makeContent: "Create article",
    manageBlogCategories:"Categories",
    manageBlogComments:"Manage comments",
    contents: "Phil Mag",
    addContent: "Create new post",
    viewContent: "View previous posts",
    editContent: "Edit post",
    contentTitle: "Article Title",
    published: "Published",
    draft: "Draft",
    publishStatus: "Publish Status",
    viewArticle: "View Article",
    relatedAds: "Related Showcases",
    chooseAd: "Choose Showcase",
    addAd: "Add Showcase",
    tickets: "Support Ticket",
    manageMessages: "Manage Messages",
    manageTickets: "manage tickets",
    manageDepartments: "Manage Departments",
    requestType: "Request type",
    supportRequestFromDepartment: "System Support",
    supportRequestForProduct: "Product support",
    sendFile: "Send file",
    ticket_sender: "Sender",
    ticket_receiver: "Recipient",
    receiver: "Recipient",
    ticket_title: "Topic",
    ticket_status: "Status",
    ticket_edited_at: "Last posted",
    sendMessage: "Send message",
    backToTickets: "Back to tickets",
    deleteTicket: "Ticket deleted!",
    deleteTicketFailed: "Failed to delete ticket!",
    ticket_details: "Ticket Details",
    ticket_sent: "Ticket sent!",
    sendFastTicket: "Send fast ticket",
    sendMessageTo: "Send message to",
    addTicket: "Send Ticket",
    send: "Send",
    messageText: "Message text",
    gotToTicketPage: "Messages page",
    removeRequest: 'Remove request',
    related_admin: "Related admin",
    department: "Department",
    departments: "Departments",
    low: "low",
    medium: "Medium",
    high: "high",
    subject: "Subject",
    message: "Message",
    priority: "Priority",
    unread: "Awaiting review",
    read: "Read",
    sentTicket: "Sent",
    sent: "Sent",
    referToMe: "Referred to me",
    resumeOrder: "Continue Order",
    bill: "Bill",
    checkout: "Order completion",
    totalPrice: "Total Price",
    productsInCart: "Items in cart",
    totalCartPrice: "Cart Total",
    totalUserDiscounts: "Total discounts",
    totalDiscounts: "Your profit",
    makeOrder: "Make order",
    orderPayed: "Order is paid.",
    orderPayingFailed:"There is a problem in paying the amount!",
    pending: "Awaiting approval",
    not_payed:"Pending payment",
    viewOrders: "View Orders",
    viewOrdersDetails: "View all your past orders from this section!",
    viewOrder: "View order",
    orders: "Orders",
    ordersList: "My orders list",
    orderDetails: "Order Details",
    productsList: "Purchased Items",
    purchase: "Payment",
    payableMoney: "Amount payable",
    payOrder: "Pay Order",
    order_status: "Order status",
    factor_number: "Invoice number",
    factor_status: "Invoice status",
    created_at_factor: "Order date",
    mobileNumber: "Mobile Number",
    status: "Status",
    payed: "Paid",
    not_used: "not used",
    used: "Used",
    voucher: "Voucher",
    voucher_expired_at: "Expiration time",
    getVoucher: "Get Voucher",
    unique_key: "Unique code",
    not_use: "Not used",
    withoutExpire: "Without expiration",
    manageOrders: "Manage orders",
    dataRemoved: "Data removed successfully",
    dataRemovingFailed: "There was a problem removing data.",
    buyer: "Buyer",
    purchasedVouchers: "Vouchers sold",
    singular_price: "Single price",
    viewVouchers: "View vouchers",
    viewVoucher: "View Voucher",
    doesntHave: "Doesn't have",
    dateShouldBeAfterExpire: "The expiry time is wrong!",
    expire_after_purchase: "Expiration",
    fieldEnglishName: "English Field name",
    fieldDescription: "Persian Field Description",
    fieldDescriptionEnglish: "English field description",
    editField: "Edit Field",
    businessFields: "Business Fields",
    advertisementFields:"Showcase fields",
    discountFields: "Discount Fields",
    globalFields: "Global Fields",
    fields: "Fields",
    uniqueKey:"Unique key",
    isThisFieldRequired: "Required field!",
    field_type: "Field Type",
    text: "Text",
    long_text:"long text",
    select_field: "selected field",
    multi_select_field: "multi-select field",
    options: "Persian Options",
    english_options:"English Options",
    pressEnterToMakeEachOption:"Press Enter to make each option!",
    units: "Persian Units",
    english_units: "English Units",
    pleaseChooseACategoryForFields: "If needed, you can choose your desired category and create its own fields",
    pleaseChooseCategoryFirst:"Please choose your desired category first",
    total_employees: "Number of employees",
    certificates: "standards",
    certificate: "standard",
    addCertificate:"Add Standard",
    editCertificate:"Edit Standard",
    aboutBusiness: "About Business",
    equalCategory:"Persian Equal",
    openControl: "Advanced Editor",
    standards: "standards",
    standardsPlaceHolder:"ISO:9002...",
    relatedDiscount:"Related discount",
    relatedDiscountDesc:"If you have a discount related to this Showcase, select it to be displayed on the Showcase page.",
    chooseADiscount:"Choose a discount",
    chosenDiscount:"selected Discount",
    iconsList: "List of icons",
    changeMobileOrEmail: "Change mobile number or email",
    changeMobileOrEmailDesc:"If the information changes, reconfirmation is required.",
    anotherUserHasThisInfo:"The entered information has already been registered by another user.",
    vouchersList:"Vouchers List",
    newsletterSubscribers:"Subscribers",
    hoverImage:"Hover image",
    isThisAdBest: "Should it be displayed in the top showcases?",
    isThisDiscountBest:"Should it be displayed in the Special discounts?",
    isVerifiedDescription:"Is this business verified?",
    isDiscountProviderDescription:"Is this business a discount provider?",
    imageProperWidthHeight: "Proper Width & Height",
    imageProperSize: "Maximum image size",
    videoProperSize: "Maximum video size",
    createBusiness: "Create Business",
    relatedUser:"Related user",
    chooseAUser:"Choose a user!",
    relatedUserBusiness: "Related User / Business",
    inCaseOfNotChoosingUser: "If you do not select a user, the showcase will be registered for you",
    pleaseCompleteYourInfoFirst:"Please complete your account information first!",
    readBusinessLocation: "Read from business location",
    inCaseOfNotChoosingUserDiscount: "If you do not select a user, the discount will be registered for you",
    youCantAddDiscount:"Discount cannot be created",
    youCantEditDiscount:"Unable to edit discount",
    planLimitationDescriptionDiscount: "According to your plan, it is not possible to do this, please see the Pricing and prepare your suitable plan!",
    makeVouchers: "Create Voucher",
    typeOfDiscount: "Choose how to create a discount code",
    discountBySystem: "System import",
    discountByManual: "Manual import",
    makeDiscountCodes: "Make discount codes",
    discountCode: "Discount code",
    pleaseFillFiledBefore: "Please fill the previous field",
    importFromExcel: "Import from Excel",
    wrongFormat: "The selected format is wrong!",
    makeBlogCategory: "Create category of articles",
    editBlogCategory: "Edit Article Category",
    menuImage: "Inside image",
    changeColor: "Change color",
    bg_color: "Background",
    main_color: "Main color",
    content_type: "Content Type",
    article: "Article",
    tricks: "Tricks",
    bests: "The best",
    attractions: "Attractions",
    reviews: "Reviews",
    none: "None",
    articlesGallery: "Articles Gallery",
    relatedBusinesses: "Related businesses",
    chooseBusiness: "Choose Business",
    addBusiness: "Add Business",
    keywordsLabel: "Keywords",
    allKeywordsAreTyped: "You cannot add more keywords.",
    keywordsDescription: "Write related tags.",
    pressEnterAfterEachKeyword: "Press the Enter button after writing each keyword!",
    is_featured_article:"Is this article among the top articles?",
    news: "Phil News",
    manageNews: "News Management",
    newsList: "News list",
    makeSingleNews: "Create News",
    manageNewsCategories: "News categories",
    addSingleNews: "Create new news",
    viewSingleNews: "view news",
    editSingleNews: "edit news",
    singleNewsTitle: "News Title",
    makeNewsCategory: "Create news category",
    editNewsCategory: "Edit News Category",
    newsGallery: "News Gallery",
    news_expiration: "News expiration days",
    ads_expiration: "Showcase expiration days",
    shouldNewsExpire: "This item has an expiration date.",
    source: "Source",
    source_url: "Source link",
    manageArticlesComments: "Comment on articles",
    manageNewsComments: "Comment on news",
    content: "Article",
    step1: "Initial registration",
    step2: "Complete the form",
    step3: "Upload documents",
    step4: "Awaiting approval",
    step5: "Requires modification",
    step6: "Confirm",
    brand_name: "Brand name",
    license_number: "License Number",
    license_expiration_date: "license expiration date",
    companyName: "Company Name",
    saveAndContinue: "Save and continue",
    pleaseConfirm: "Please confirm",
    withRegisteringAnAccount: "By creating an account, with ",
    and: "and",
    termsAndConditions: "Terms and Conditions",
    termsAndConditionsBusiness: "Business Terms and Conditions",
    tradeLicense: "Trade License",
    tradeLicenseContract: "Trade License/Contract",
    emiratesCard: "Emirates Card",
    pleaseChooseAFile: "Please choose a file",
    yourInfoIsUnderChecking: "Your information is being reviewed",
    yourInfoIsUnderCheckingDesc: "After checking by site experts, you will be informed about the result of registration",
    confirmRegisterInfo: "Confirm registration information",
    rejectRegisterInfo: "Information rejected",
    areInfosConfirmed: "User registration information is confirmed",
    faults: "Registration form faults",
    registerInfo: "Registration Information",
    registerInfoNeedApprovalTitle: "Check registration information",
    registerInfoNeedApproval: "The user's registration information needs to be checked, please check and approve or reject the items",
    yourInfoIsVerified: "Your registration information has been successfully verified",
    yourInfoIsVerifiedDesc1: "Your information has been verified and you have been assigned a ",
    yourInfoIsVerifiedDesc2: " plan.",
    yourInfoIsChecked: "Your registration information has been checked",
    pleaseReadAndDo: "Please read the following and edit your information",
    editInfo: "Edit Info",
    managePlanParents: "Grouping of Plans",
    plans: "Plans",
    makePlanParent: "Create Plan Parent",
    planName: "Plan Name",
    planEnglishName: "English plan name",
    description: "Description",
    english_description: "English description",
    editParent: "Edit Plan Group",
    makeParent: "Create Plan Group",
    planFeatures: "Plan Features",
    planEnglishFeatures: "Plan English features",
    managePlans: "Manage Plans",
    days: "day",
    editPlan: "Edit Plan",
    makePlan: "Create Plan",
    planParent: "plan group",
    discountPrice: "discounted price",
    durationPlan: "Duration of Validity",
    ad_count: "Number of free showcases",
    edit_count: "Number of edits and updates",
    photography: "number of promotional photos",
    verify_badge: "Verify Badge",
    purchase_ad: "Possibility to purchase showcase",
    has_business_page: "Business page with special features",
    has_discount: "The possibility of creating discounts",
    has_teaser: "Make promotional teaser",
    has_introducing_video: "Making introduction video",
    philmag: "Exclusive Philmag",
    has_banner_design: "Exclusive banner design for business page",
    has_commercial_banner: "Insert a special banner in different parts of the site",
    has_link: "link to social pages",
    has_support: "24-hour support and Chat",
    has_distance: "display the time distance of the showcase/discount to the visitor",
    free: "Free",
    discountMoreThanPrice: "The discount amount is more than the original amount",
    pageCover: "page banner",
    color: "color",
    faq_categories: "FAQ categories",
    FAQCategory: "Category",
    purchase_ads_page: "Showcase and Plan orders page",
    page_title: "Page Title",
    english_page_title: "English page title",
    buyAd: "Buy Showcase",
    month: "Mmonth",
    registerBusiness: "Register Business",
    planValidity: "Valid until",
    viewPlans: "View plans",
    has_ad: "Possibility to create showcase",
    expirationDate: "Expiration date",
    renew: "Renewal",
    renewAd: "Expired Showcase Renewal",
    renewAdDesc: "You can renew your expired storefront to be listed again!",
    monthlyAdRenewCount: "The number of Showcases that can be created / renewed",
    myPlan: "My Plan",
    reserved_plan: "Reserved plan",
    planDuration: "Duration of the plan",
    staringDate: "start time",
    voucherCode: "Voucher code",
    addMoreField: "Add more code",
    pleaseAddSomeCodes: "Please enter your discount voucher codes",
    wrongVoucherCode: "The voucher code is wrong.",
    someVoucherCodesAreDuplicated: "Some codes are duplicated, please correct them",
    manageVouchers: "Manage Vouchers",
    availableVouchers: "Available vouchers",
    addMoreVouchers: "Add more vouchers",
    not_purchased: "Not purchased",
    voucherTypeSystem: "Create voucher code by the system",
    voucherTypeSystemDesc: "In this case, you only specify the number of discount codes you can sell and the system will generate these codes for you.",
    voucherTypeManual: "Create voucher code by you",
    voucherTypeManualDesc: "In this case, you enter the discount voucher codes yourself and you will be able to sell discounts according to the number of codes you enter.",
    voucherTypeManualDescStrong: "Voucher codes must contain only numbers and letters and at least 5 characters",
    forPurchasingBetterPlansAdsAndReserveGoToPlans: "Refer to the purchase page to purchase a better plan, renew the plan, and purchase a showcase.",
    plansPage: "View page",
    yourDiscountIsActive: "Congratulations! Your discount is confirmed and active.",
    yourDiscountIsInchecking: "Your Discount is in check!",
    makeVouchersForEqual: "Create voucher codes for Persian equivalent",
    voucherEn: "Voucher",
    getAllVouchers: "get all vouchers",
    vouchersInfo: "Vouchers Info",
    total_vouchers: "Total number of vouchers",
    purchased_vouchers: "Sold Vouchers",
    not_purchased_vouchers: "Remaining vouchers",
    verificationDescription: "In order to send a deposit request, you need to confirm the bank account information",
    verificationDetails: "Bank Account Information",
    verificationDataSets: "Bank account information registered!",
    manageVerifications: "Manage banking information",
    ManageUserVerification: "Manage user bank account information",
    viewUserBankInfo: "View user's bank account information",
    yourBankInfoIsActive: "Your bank account information is confirmed and you can request a deposit.",
    yourBankInfoIsInchecking: "Your bank account information is in check.",
    payment_type: "Bank account type",
    credit_number: "Credit card number",
    paypal_username: "Paypal Username",
    paypal: "Paypal",
    credit_card: "Credit Card",
    viewRegisterInfo: "View registration information",
    viewRegisterInfoHint: "You can see your registration information in this section. If you need to change the information, Submit a ticket.",
    submitTicket: "Submit Ticket",
    buyersOrdersList: "Customer’s order history",
    manageWallets: "Manage Transactions",
    paymentManagement: "Mange payment",
    amount: "Amount",
    transactionType: "Transaction Type",
    withdrawMoney: "Deposit request",
    withdrawMoneyDescription: "Enter the amount you want to deposit.",
    deposit:"Charge",
    withdraw: "Withdrawal",
    transactionTrackingCode: "Transaction Tracking Code",
    addRefrenceIdFirst: "Enter the transaction tracking code first!",
    balance: "Balance",
    manageUserWallet: "Manage user transactions",
    myTransactions: "My Transactions",
    youHavePendingWithdrawRequest: "You have a request pending review!",
    wrongPrice: "The amount is wrong",
    cantWithdrawSmallerThan10: "Can't withdraw less than 10AED!",
    pleaseVerifyFirst: "Please complete your bank account information first!",
    withdrawRequest: "Deposit request",
    myWalletBalance: "withdrawal balance",
    boxActiveStatus: "Activity status of information box",
    inactive: "Inactive",
    withBordered: "Bordered",
    withoutBordered: "Without border",
    discount_coins: "Number of coins received",
    userPlanInfo: "User plan information",
    givePlanToUser: "Give the plan and showcase to the user",
    choosePlan: "Choose plan",
    plan: "Plan",
    visitsChart: "Visits chart",
    liveUsers: "Live users",
    newRegistrations: "Today’s New Users ",
    todayAllUnRegisteredUsers: "Today’s all visitors",
    daily_discount_notif: "Sending a push notification once a day if there is a new discount (link to all discounts)",
    each_discount_notif: "Sending a push notification for each new discount (link to the discount page)",
    newAds: "Today's Showcases",
    newDiscounts: "Today's Discounts",
    sellerGuidLine: "Easy Access",
    submitCompanyInfo: "Complete the process",
    letsGo: "view",
    enjoyPremiumFeatures: "Enjoy premium features",
    becomePremium: "Become Premium",
    upgradeYourPlan: "Upgrade Your Plan",
    companyPage: "Business Page",
    createBusinessPageToSee: "Create your business page to see its information in this section",
    numberOfAds: "Number of showcases",
    upgradeYourPlanToCreateAds: "Upgrade your plan to create a showcase",
    numberOfDiscounts: "Number of discounts",
    totalVouchersSold: "Sold Vouchers ",
    viewList: "View",
    removeBusiness: "Delete Business page",
    deleteBusiness: "Successfully deleted business page",
    deleteBusinessFailed: "Failed to delete business page",
    reserve_description: "Reservation Description",
    ordersListManage: "orders list",
    sendMessageInWhatsapp: "Message in Whatsapp",
    reserveDiscount: "Discount Booking",
    reserve_whatsapp_link: "Booking link on WhatsApp",
    reserveRequest: "Booking request",
    reserveRequestText: "Hello, I would like to book this discount",
    viewReserveRequest: "View booking request",
    reserve: "Reserve",
    steps_banner: "Business Membership Page Banner",
    report: "Send report",
    addReport: "Send Report",
    youCantDeleteThisDepartment: "It is not possible to delete system departments",
    yourTotalCoins: "The number of your Phil Coins",
    multipleBusinessAddingFailed: "It is not possible to create more than one business per user",
    ifYouWantToSeeMoreInfoClick: "If you want to see the full information of this page",
    clickHere: "Click Here",
    attentionText: "Please note that if you click, you will enter the English panel",
    pleaseCompleteYourInfo: "Please complete your register information",
    youAreViewingUserRegisterInfo: "Dear admin, you are viewing user registration information",
    toViewContentsInOtherLanguageToggleLanguage: "Change the language to view items in Farsi",
    equal: "Farsi",
    logo: "Logo",
    total_price: "Total price",
    factor_type: "Order type",
    buyDiscount: "Discount",
    buyPlan: "Plan",
    buyAdvertisement: "Showcase",
    registrationCompleted: "Confirmed",
    removeFilter: "Remove Filter",
    myBusinessPage: "My business page",
    editDetailss: "Edit details",
    registerInfoDone: "You can edit user register information in this section",
    youCanEditUserRegisterInfo: "To view and edit user register info use this button",
    deletePlanParentFailed: "To delete a plan group, first delete the plans of this group",
    deletePlanFailed: "One or more users are using this plan",
    usedDate: "Voucher usage date",
    inventoryName: "Quantity",
    monthlySales: "Monthly Sales",
    totalSales: "Total Sales",
    totalSale: "Total Sales",
    plansSales: "Plan orders",
    adsSales: "Showcase orders",
    discountsSales: "Discount orders",
    purchaseAd: "Purchase Showcase",
    ad_count_admin: "Count of Showcases",
    verifiedUsers: "Verified users",
    verifiedUser: "Verified user",
    normalUser: "Normal User",
    completingForm: "Completing",
    rejected: "Rejected",
    userType: "User type",
    removeSelect: "Deselect",
    userSuspensionStatus: "user Suspension",
    userSuspensionStatusDescription: "From this section, you can set the status of the user's registration and plan to suspended",
    suspended: "Suspended",
    yourAccountIsSuspended: "Your account is suspended",
    changeCover: "Change preview",
    removeVideo: "Remove Video",
    invalidEmail: "This Email does not exist.",
    recoverByEmail: "Recover with email address",
    recoverByMobile: "Recover with mobile number",
    recoverPasswordTextEmail:"Enter your Email address to recover your password.",
    sendTokenTextEmail: "The one-time password has been sent to your email",
    sendTokenTextEmail3: "Time left to resend email",
    changeToEmail: "Change to Email",
    changeToMobile: "Change to Mobile",
    confirm_payment: "Payment confirmation",
    fileSize: "Size",
    youCantAddToCart : "It is not possible to add more to the shopping cart",
    farsiDescription : "Farsi Description",
    englishDescription : "English Description",
    has_banner : "Ability to place a banner in business page",
    has_contact: "Ability to display contact information, address and location in business page",
    has_video : "Ability to upload video",
    has_business_about : "Ability to write text about business",
    has_business_related_articles: "Ability to display articles related to business",
    business_thumbnail_desc : "The main view of your business in the list of businesses" ,
    tax_notic : "5% tax is included" ,
    doFilter : "Filter" ,
    searchUser : "Search in users" ,
    userCurrentStep: "Current step of the user",
    pleaseChooseARadio : "Please choose an item" ,
    businessType: "Type of business",
    personBusiness: "Natural person",
    companyBusiness: "Legal person",
    no_license: "I do not have a valid license",
    user_no_license: "No valid license",
    download: "Download",
    downloadContract: "Download contract",
    no_license_desc_1 : "To register in Phil, please download the contract and upload it in the above section after signing.",
    no_license_desc_2 : "You can return to this menu at any time" ,
    addFile: "Add file",
    removeFile: "Remove File",
    uploaded: "Uploaded",
    reserveSituation: "How to activate the new plan",
    do_reserve: "The plan will be reserved until the end of the current plan",
    do_renew: "The plan replaces the current plan now",
    invite_code: "Invite code",
    invite_code_copied: "Invite code copied",
    invite_text: "Invitation to Phil",
    invite_text_1 : "My friend, you have been invited to Phil by Dear" ,
    invite_text_2 : "" ,
    invite_text_3 : "Complete your business registration process and enter the Invite code in the referral code field." ,
    invitationText : "How many people do you know who are business owners? Refer Phil and get a gift" ,
    sendInvitation: "Send Invitation",
    referral_code : "Referral Code" ,
    referral_code_desc : "Insert your Referral code" ,
    seoDetails : "Manage SEO" ,
    makePageNoIndex : "Do not display to search engines (No Index)" ,
    ad_meta_title : "Showcase Meta Title" ,
    business_meta_title : "Business Meta title" ,
    discount_meta_title : "Discount Meta title" ,
    meta_title : "Meta Title" ,
    makeAdPageNoIndex : "Do not display Showcase directory to search engines (No Index)" ,
    makeDiscountPageNoIndex : "Do not display Discount directory to search engines (No Index)" ,
    makeBusinessPageNoIndex : "Do not display Business directory to search engines (No Index)" ,
    discount_description: "Discounts Brief description",
    business_description: "Business Brief description",
    ad_description: "Showcase Brief description",
    manageCTAs: "Manage CTAs",
    addCTA: "Add CTA",
    ctaTitle: "Main title",
    subtitle: "Bottom text",
    editCTA: "Edit CTA",
    makeCTA: "Create CTA",
    btn_color: "Button color",
    txt_color: "Title Color",
    btn_number: "Number button",
    btn_name: "Button name",
    btn_link: "Button link",
    btn_icon: "Button icon",
    isNewsLetter: "Activate newsletter mode",
};