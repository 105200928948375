import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {getTimeZone, scrollTop, truncate} from "../../../config/values";
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import {makeSlug, translate} from "../../../config/lang";
import MakeEqualNews from "./MakeEqualNews";

class News extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    componentDidMount() {
        scrollTop()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                hidden: this.props.width === "xs",
                title: translate('thumbnail'),
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            {
                title: translate('singleNewsTitle'),
                field: 'title',
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <Button
                        size="small"
                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                        component="a"
                        href={makeSlug(`news/${rowData.slug}`)}
                        target="_blank"
                    >
                        {truncate(rowData.title,17)}
                    </Button>
            },
            {
                title: translate('equalItem'),
                field: 'name',
                sorting:false,
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <>
                        {
                            !this.state.loading ?
                            <MakeEqualNews
                                list
                                equal_id={!!rowData.equal_id ? rowData.equal_id : null}
                                item={rowData.item}
                                reload={() => this.loadData()}
                            /> :
                                <span></span>
                        }
                    </>

            },
            {
                title: translate('category'),
                field: 'categories',
                filterPlaceholder: translate('search'),
                hidden: this.props.width === "xs",
            },
            { title: translate('publishStatus'),
                editable: 'never',
                field: 'publish',
                filterPlaceholder: translate('choose'),
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('published')}</Typography>
                    , 0:
                        <Typography color="textSecondary">{translate('draft')}</Typography>
                },
            },
            {
                title: translate('updateTime'),
                editable: 'never',
                hidden: this.props.width === "xs",
                field: 'updated_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/edit/news/${rowData.id}`}
                        endIcon={<ChevronRightRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {translate('edit')}
                    </Button>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange();
        this.setState({
            loading:true
        })
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/news/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteContent'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.loadData();
                this.props.enqueueSnackbar(translate('deleteContentFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <Container maxWidth="xl">
                <MaterialTable
                    tableRef={this.tableRef}
                    icons={tableIcons}
                    title={
                        <Link to='/create/news'>
                            {
                                this.props.width !== "xs" ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddBox />}
                                    >
                                        {translate('addSingleNews')}
                                    </Button> :
                                    <IconButton color="primary">
                                        <AddBox />
                                    </IconButton>
                            }
                        </Link>
                    }

                    localization={getDataTableLocalization()}
                    columns={this.state.columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            axios.get(`${defaultVariabls.url}/news/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&lang=${this.props.fake_locale}`, config)
                                .then(async response => {
                                    await this.setState({
                                        loading:true
                                    })
                                    let data = [];
                                    // eslint-disable-next-line
                                    response.data.data.map((row) => {
                                        let categories = '';
                                        // eslint-disable-next-line
                                        row.categories.map(item => {
                                            categories += item.name;
                                            categories += " , "
                                        })
                                        categories = categories.substring(0, categories.length - 2)
                                        data.push({
                                            thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                            title: row.title,
                                            slug: row.slug,
                                            id: row.id,
                                            publish: row.publish,
                                            updated_at: row.updated_at,
                                            equal_id: row.equal_id,
                                            item: row,
                                            categories: categories,
                                        })
                                    });
                                    resolve({
                                        data: data,
                                        page: response.data.current_page - 1,
                                        totalCount: response.data.total,
                                    })

                                    await setTimeout(() => {
                                        this.setState({
                                            loading:false
                                        })
                                    }, 1000);
                                }).catch(error => {
                                console.log(error.response)
                            })
                        })
                    }
                    options={{
                        pageSize: TablePage,
                        pageSizeOptions: TableCount,
                        draggable:false,
                        sorting:false,
                    }}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.onDelete(oldData.id);
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}

                />
            </Container>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
    fake_locale: state.fake_locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(News)))));